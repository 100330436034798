import React, { useEffect, useState } from "react"
import Image from "next/image";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { get, map } from "lodash"
import {useTranslation} from "react-i18next";

//i18n
import languages from "../languages"
import {useAuth} from "../providers/AuthProvider";
import {useFirebase} from "../providers/FirebaseProvider";

const LanguageDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState("ja")
  const [menu, setMenu] = useState(false)
  const {i18n} = useTranslation()
  const {userData} = useAuth() ?? {}
  const {firebase} = useFirebase()

  const changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang)
    //save the lang
    localStorage.setItem("i18nextLng", lang)
    if(userData && firebase){
      firebase.updateUserData({...userData,preferredLanguage: lang})
    }
  }

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn waves-effect" tag="button">
          <img
            src={get(languages,`${i18n.language}.flag`)}
            alt="Flag"
            height={16}
          />
        </DropdownToggle>
        <DropdownMenu className="language-switch" right>
          {map(Object.keys(languages), key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${
                selectedLang === key ? "active" : "none"
              }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Flag"
                className="mr-1"
                height="12"
              />
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default LanguageDropdown
