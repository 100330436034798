import React, {useMemo, useState} from "react";
import Link from "next/link";
import {useRouter} from "next/router";
import LanguageDropdown from "./LanguageDropdown";
import {useTranslation} from "react-i18next";
import ProfileMenu from "./ProfileMenu";
import {UncontrolledTooltip,Badge,Button} from "reactstrap";
import {useAuth} from '../providers/AuthProvider'
import { platformHelper } from "../lib/helpers";
import { Row, Col} from "reactstrap";
import axios from 'axios'
import Logo from "./Logo";
const Header = ({title, firebase}) => {

  const {viewCountAndLimit,userData,currentPlan,authUser} = useAuth() ?? {}
  const platform = platformHelper(userData)
  const isLoggedIn = () => {
    return userData?.userId
  }

  const router = useRouter();
  const {t} = useTranslation();

  const goPricePage = async () => {
    if(platform.pricingUrl.match(/^http/))
      window.open(platform.pricingUrl,'_blank')
    else
      await router.push(platform.pricingUrl)
  }

  const showUsage = useMemo(() => {

    if (viewCountAndLimit) {
      let { limit, viewCount} = viewCountAndLimit

      if (limit > 0) {
        const usageRate = viewCount / limit * 100

        if (usageRate >= 100) {
          return (
            <>
              <Badge color="danger" className="mr-3 p-2" id="usageIndicator"><i
                className="bx bx-bell bx-tada"/> {usageRate.toFixed(1)}%</Badge>
              <UncontrolledTooltip placement="right" target="usageIndicator">
                {t('You have reached your monthly usage cap! Click to upgrade and increase your limit.')}
              </UncontrolledTooltip>
            </>)
        } else if (usageRate > 80) {
          return (
            <>
              <Badge color="warning" className="mr-3 p-2" id="usageIndicator">{usageRate.toFixed(1)}%</Badge>
              <UncontrolledTooltip placement="right" target="usageIndicator">
                {t('You are about to reach your monthly usage cap! Click to upgrade and increase your limit.')}
              </UncontrolledTooltip>
            </>)
        } else {
          return (
            <>
              <Badge color="success" className="mr-3 p-2" id="usageIndicator">{usageRate.toFixed(1)}%</Badge>
              <UncontrolledTooltip placement="right" target="usageIndicator">
                {t("Current months usage limit")}
              </UncontrolledTooltip>
            </>)
        }
      }
    }
  }, [viewCountAndLimit])


    const usageWarningBar = useMemo(() => {

        if (viewCountAndLimit) {
            let { limit, viewCount} = viewCountAndLimit

            if (currentPlan.planname === 'Free') {
                const usageRate = viewCount / limit * 100
                if (usageRate >= 100) {
                    return (<div className="bg-danger text-white text-center p-3">
                        <Row className="align-items-center align-content-center justify-content-center">
                            <Col className="pb-1 pb-sm-0" sm={1}><i className="mdi mdi-alert-outline mdi-24px" /></Col>
                            <Col className="pb-3 pb-sm-0" sm={9}><strong>{t('You have reached 100% of your view limit. Displays are stopped.')}<br />{t('Upgrade now to restart displays.')}</strong></Col>
                            <Col sm={2}><button onClick={goPricePage} className="btn btn-hollow-custom">{t('Upgrade')}</button>
                            </Col>
                        </Row>
                    </div>)
                } else if (usageRate > 0) {
                    return (<div className="bg-success text-white text-center p-3">
                        <Row className="align-items-center align-content-center justify-content-center">
                            <Col className="pb-1 pb-sm-0" sm={1}><i className="mdi mdi-alert-outline mdi-24px" /></Col>
                            <Col className="pb-3 pb-sm-0" sm={9}><strong>{t('You are currently using the free version, limited to 100 views.')}<br />{t('Upgrade now to get unlimited everything.')}</strong></Col>
                            <Col sm={2}><button onClick={goPricePage} className="btn btn-hollow-custom">{t('Upgrade')}</button></Col>
                        </Row>
                    </div>)
                } else {
                    return null
                }
            }
        } else return null
    }, [viewCountAndLimit])

	const clicked = async () => {
		//const token = await authUser.getIdToken()
		//const res = await axios.post('/api/templateChecker', {token})
    firebase.refreshKlaviyo(userData)
	}
  return (
    <header id="page-topbar">
      <nav className="navbar-header navbar-dark px-1 px-lg-5">
        <div className="logo-wrapper mr-2 mr-lg-4">
          <div className="navbar-brand-box">
            <Link href="/" className="logo">
              <a className="d-flex align-items-center" style={{width: '130px'}}>
                <div className="logo-sm">
                  <Logo mode="light" />
                </div>
              </a>
            </Link>
          </div>
        </div>
        <div className="navbar-nav d-flex">
          <div className="navbar-main-menu">
            {isLoggedIn() && (
              <div className="d-flex w-100">
                <Link href="/displays">
                  <a className="nav-link d-flex align-items-center mr-2">
                    <i className="mdi mdi-monitor-star mdi-18px mr-2" />
                    <span className="d-none d-xl-inline">{t('Dashboard')}</span></a>
                </Link>
                <Link href={`/lists/${userData.lists[0].id}`}>
                  <a className="nav-link d-flex align-items-center mr-2">
                    <i className="mdi mdi-view-list-outline mdi-18px mr-2" />
                    <span className="d-none d-xl-inline">{t('Lists')}</span></a>
                </Link>
                <Link href={platform.type==='generic' ? "/coupons":"/coupon-histories"}>
                  <a className="nav-link d-flex align-items-center mr-2">
                    <i className="mdi mdi-ticket-percent-outline mdi-18px mr-2" />
                    <span className="d-none d-xl-inline">{platform.type==='generic' ? t("Coupons"): t('Coupon history')}</span></a>
                </Link>
                <Link href="/email-templates">
                  <a className="nav-link d-flex align-items-center mr-2">
                    <i className="mdi mdi-email-edit-outline mdi-18px mr-2" />
                    <span className="d-none d-xl-inline">{t('Auto-responders')}</span></a>
                </Link>
								<Button id="my-button" className="d-none" onClick={clicked}>Execute</Button>
              </div>
            )}
          </div>

          <div className="navbar-user-menu">
              <LanguageDropdown/>
              <ProfileMenu platform={platform} userData={userData} firebase={firebase} authUser={authUser} currentPlan={currentPlan}/>
          </div>
        </div>
      </nav>
        {usageWarningBar}

      <style jsx>{`
        .with-badge{
        position:relative;
        }
        .badge{
          position: absolute;
          top:-3px;
          left:50%;
          transform: translate(-50%,0);
        }
      `}
      </style>
      <style jsx global>{`
          #usageIndicator .bx-bell::before{
            font-size:1.5em;
            vertical-align:middle;
            padding-right:5px;
          }
          .btn-hollow-custom{
          background-color:transparent;
          border:1px solid #fff;
          color:#fff;
          cursor: pointer;
        }
      `}
      </style>
    </header>
  )
}

export default Header;
