import React from "react";

const ChromelessLayout = ({children, error, title}) => {

    return (
        <>
            {error ? (<div>{error}</div>) : <div>{children}</div>}
        </>
    );
};
export default ChromelessLayout