import React, {useState,useEffect,createContext,useContext} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Toast,ToastHeader,ToastBody, Alert} from "reactstrap";
import {useTranslation} from "react-i18next";
import classnames from 'classnames'
const ModalContext = createContext();
const ModalProvider = ({children}) => {
  const [visible, setVisible] = useState(false)
  const [modalOptions,setModalOptions] = useState({})
  const {t} = useTranslation()
  const toggle = () => setVisible(!visible)

  const hideModal = () => {
    setVisible(false)
  }

  const showModal = (options) => {

    setModalOptions(options)

    setVisible(true);

  }

  const errorHeader = <ModalHeader className="alert-danger">{t('Oh no! There was an error.')}</ModalHeader>

  const {
    message = '',
    size = 'md',
    title = '',
    innerComponent,
    isError = false,
    hideHeader = false,
    hideFooter = true,
    backdrop = true,
    scrollable = false,
    classNames = {}
  } = modalOptions


  return (
    <ModalContext.Provider value={{showModal,hideModal}}>
      {children}
      <Modal  isOpen={visible} centered={true} backdrop={backdrop} toggle={toggle} size={size} scrollable={scrollable}>
        {isError ? errorHeader :
          !hideHeader && <ModalHeader toggle={toggle} className={`modal-provider ${classnames(classNames)}`}>{title}</ModalHeader>
        }
        <ModalBody>
          {innerComponent ? innerComponent : <div dangerouslySetInnerHTML={{__html:message}}></div>}
          {isError && (<Alert className="mt-2" color="warning"><a style={{color:"black"}} href="https://unlimited-spin-to-win.groovehq.com/help/i-can-t-see-my-spin-to-win" target="_blank">{t('If this error continues please contact support here.')}</a></Alert>) }
        </ModalBody>
        {!hideFooter && <ModalFooter>
          <Button color="warning" onClick={() => setVisible(false)}>{t('Close')}</Button>
        </ModalFooter>
        }
      </Modal>
      <style jsx>{`
        	:global(.modal-provider.modal-header){
            background-color: #f3f3f9;
          }
          :global(.modal-provider.modal-header .close){
            top: 15px;
            right: 15px;
          }
          :global(.no-title-close .close){
            top: 15px;
            right: 20px;
          }
          :global(.modal.show){
          	background-color:${backdrop ? 'rgba(0, 0, 0, 0)' : 'rgb(0,0,0,0.3)'};
          }
         	
        `}</style>
    </ModalContext.Provider>
  );
};

const useModal = () => useContext(ModalContext)


const ToastContext = createContext();
const ToastProvider = ({children}) => {
  const [visible, setVisible] = useState(false)
  const [message, setMessage] = useState("")
  const [title, setTitle] = useState("")
  const [className, setClassName] = useState("info")
  const [isError, setIsError] = useState(false)
  const {t} = useTranslation()
  const toggle = () => {
    setVisible(!visible)
  }

  const showToast = ({title,message,className,isError,timeout = 5000}) => {
    setTitle(title)
    setMessage(message)
    setVisible(true)
    setClassName(className)
    setIsError(isError)
    setTimeout(() => {
      setVisible(false)
    },timeout)
  }
  const errorHeader =  <ToastHeader toggle={toggle} icon="danger">{t('Oh no! There was an error.')}</ToastHeader>
  return (

    <ToastContext.Provider value={{showToast}}>
      {children}
      <Toast isOpen={visible} style={{'position':'fixed', 'bottom':'15px', 'right': '15px'}}>
        {isError ? errorHeader :
          <ToastHeader toggle={toggle} icon={className}>
            {title}
          </ToastHeader>
        }
        <ToastBody>
          {message}
          {isError && (<Alert className="mt-2" color="warning"><a target="_blank" href="https://unlimited-spin-to-win.groovehq.com/help/i-can-t-see-my-spin-to-win" style={{color:'black'}}>{t('If this error continues please contact support here.')}</a></Alert>) }
        </ToastBody>
      </Toast>
    </ToastContext.Provider>
  );
};

const useToast = () => useContext(ToastContext)

export {useToast, useModal, ToastProvider, ModalProvider}


