import {Button,Input,Spinner,Label} from 'reactstrap'
import {useTranslation} from "react-i18next";
import axios from 'axios'
import React, {useState,useEffect} from "react";
const ShopifyEnableScript = ({authUser,userData,hideModal,firebase}) => {
  const {t,i18n} = useTranslation()
  const [themes,setThemes] = useState([])
  const [currentTheme,setCurrentTheme] = useState()
  const [selectedTheme,setSelectedTheme] = useState()

  const clicked = async () => {
    userData.platform.shownInstallationInstruction = true
    await firebase.updateUserData({...userData})
    hideModal()
  }

  const lang = i18n.language ?? 'ja'

  useEffect(async ()=>{
    if(authUser.id){
      const token = await authUser.getIdToken()
      const themes = await axios.post('/api/shopify/installedThemes',{token: token})
      const main = themes.data.find(theme => theme.role === 'main')
      setThemes(themes.data)
      setCurrentTheme(main.id)
      setSelectedTheme(main.id)
    }
  },[authUser])
  const changed = async(e) => {
    setSelectedTheme(e.target.value)
  }

  return (
  <div className="d-flex align-center flex-column justify-content-center" style={{'minHeight':'200px'}}>
    <h3 className="text-center my-3">{t('Unlimited spin to win installation')}</h3>
    <p>{t('Before Unlimited spin to win can function a script must be embedded into your website. This script loads your popups and shows them to your visitors.')}</p>
    <p>{t('To enable this script, please go to your themes settings page and ensure the \'Unlimited spin to win\' switch is toggled to the right with a blue background indicating it is active, then press the \'save\' button.')}</p>
    <p>{t('If you no longer want to utilize Unlimited spin to win this same switch can be used to deactivate it.')}</p>
    <p className="text-center">
      <img style={{width:'100%',maxWidth:'250px'}} src={`/assets/images/shopify/enable-switch-screenshot-${lang}.png`} />
    </p>

    <div className="mt-4">

      {themes.length && currentTheme ?
      <>
        <Label>{t('Select theme')}</Label>
        <Input value={selectedTheme} type="select" onChange={changed}>
          {themes.map((theme) => <option key={theme.id} value={theme.id}>{theme.name} {currentTheme === theme.id ? t('(Current)'): ''}</option>)}
        </Input>
        <div className="mt-4 text-center">
          <a  href={`https://${authUser.id}/admin/themes/${selectedTheme}/editor?context=apps&activateAppId=${process.env.NEXT_PUBLIC_SHOPIY_EXTENSION_UUID}/app-embed`} target="_blank">
            <Button color="primary" onClick={clicked}>
              {t('Enable Unlimited spin to win')}
            </Button>
          </a>
          <Button className="ml-2" color="warning" onClick={hideModal}>{t('Do it later')}</Button>
        </div>
      </>
        : <div className="pt-5 text-center"><Spinner /></div>
      }

    </div>
  </div>
  )
}

export default ShopifyEnableScript
