import React, {useState, useEffect, createContext, useContext} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useRouter} from "next/router";
import classnames from "classnames";
const ValidationContext = createContext();

const ValidationProvider = ({children}) => {

  const {t} = useTranslation()
  const {pathname} = useRouter()
  const [initialized, setInitialized] = useState()
  const {register, handleSubmit, formState: {errors},setError,setValue,getValues, reset, trigger} = useForm({
    mode: 'onChange',
  })

  useEffect(()=>{
    reset()
    setInitialized(Date.now())
  },[pathname])

  const showErrors = (id) => {
    const errorType = errors[id]?.type
    if (!errorType)
      return null

    switch (errorType) {
      case 'required':
        return <span>{t('This is required')}</span>
      case 'pattern':
        return <span>{t('This is invalid')}</span>
    }
  }

  const hasErrors = id => {
    if(id) return !!errors[id]
    else return Object.keys(errors).length > 0
  }

  const getClasses = (field) => {
    return classnames({
      "form-control": true,
      "is-invalid": hasErrors(field)
    })
  }

  const initValidator = (validationFields, validationValues) => {

    if (validationFields && validationValues) {
      const values = {...validationValues}
      validationFields.forEach(field => {
        setValue(field, values[field] ?? '')
      })
    }
  }

  return (
    <ValidationContext.Provider value={{
      showErrors,
      register,
      handleSubmit,
      hasErrors,
      errors,
      setValue,
      getValues,
      reset,
      getClasses,
      initValidator,
      initialized,
      trigger
    }}>
      {children}
    </ValidationContext.Provider>
  );
};

const useValidation = () => useContext(ValidationContext)
export {useValidation, ValidationProvider}