import React, {useState, useEffect, createContext, useContext} from "react";
import {useTranslation} from "react-i18next";
import {useFirebase} from "./FirebaseProvider";
import {useRouter} from "next/router";
import {useModal, useToast} from "./ModalProvider";
import {uniqueId} from "../utils/firebaseHandler";
import {gtagEvent, stringToBoolean, platformHelper, isGame as _isGame} from "../lib/helpers";
import _ from 'lodash';
import {useAuth} from "./AuthProvider";
import {Button} from 'reactstrap'
import contentDefault from '../components/display-content/contentsDefault'
import SummaryModal from "../components/common/SummaryModal";
import Cookies from "js-cookie";
import VisibilityGuidance from "../components/common/VisibilityGuidance";
let timeoutHandle
const DisplayContext = createContext();
const DisplayProvider = ({children}) => {
  const {firebase} = useFirebase()
  const {t} = useTranslation()
  const {push,query: {id}} = useRouter()
  const {showToast} = useToast()
  const {showModal,hideModal} = useModal()
  const [display, _setDisplay] = useState(null)
  const [mainMode, setMainMode] = useState(true)
  const [thankYouMode, _setThankYouMode] = useState(false)
  const [teaserMode, setTeaserMode] = useState(false)
  const [couponBarMode, setCouponBarMode] = useState(false)
  const [timeoutHandler, setTimeoutHandler] = useState()
  //const [autoSaveTimeoutHandler, setAutoSaveTimeoutHandler] = useState()
  const {userData,currentPlan,authUser} = useAuth()
  const [saving, setSaving] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [contentTabState, setContentTabState] = useState({activeTab:1})

  const platform = platformHelper(userData)
  const typesOfGame = ['spin-to-win']
  const AUTO_SAVE_INTERVAL = 5000

  const setDisplay = (newDisplay) => {
    const clonedDisplay = {...newDisplay}
    //set dirty
    setIsDirty(true)
    _setDisplay(clonedDisplay)
  }

  const setThankYouMode = (state) =>{
    //Reset content tab state
    setContentTabState({activeTab:1})
    _setThankYouMode(state)
  }

  const getContent = (contentId,inForm) => {
    let contentsType
    if(inForm){
      contentsType = 'forms'
    }else{
      contentsType = thankYouMode ? 'thankYouContents' : 'contents'
    }
    const content = display[contentsType].find(item => item.id === contentId)

    const theDefault = contentDefault[content?.type ?? 'heading'] ?? {}
    return {...theDefault, ...content}
  }

  const addContent = async (content) => {

    const newItem = {
      'id': uniqueId(),
      ...content
    }

    const dir = thankYouMode ? 'thankYouContents' : 'contents'

    if(dir === 'thankYouContents' && newItem.type === 'coupon')
      newItem.fixedContent = true

    display[dir].push(newItem)
    setDisplay(display)

    gtagEvent({
      action: 'componentInteraction',
      params:{
        component_type: content.type,
        action: 'add'
      }
    })
  }

  const updateContent = async (content,inForm) => {
    let dir
    if(inForm)
      dir = 'forms'
    else
     dir = thankYouMode ? 'thankYouContents' : 'contents'

    const index = display[dir].findIndex(item => item.id === content.id)
    _.forEach(content,(value,key)=>{
      content[key] = stringToBoolean(value)
    })
    display[dir][index] = content

    setDisplay(display)

    gtagEvent({
      action: 'componentInteraction',
      params:{
        component_type: content.type,
        action: 'edit'
      }
    })
  }

  const removeContent = async (content) => {
    const dir = thankYouMode ? 'thankYouContents' : 'contents'
    const index = display[dir].findIndex(item => item.id === content.id)
    display[dir].splice(index, 1);
    setDisplay(display)

    gtagEvent({
      action: 'componentInteraction',
      params:{
        component_type: content.type,
        action: 'delete'
      }
    })
  }

  const handleUpdate = async (e, saveAsTemplate) => {
    try {
      if(e) e.preventDefault()

      setSaving(true)

      if(!Cookies.get('impersonate-login'))
        display.updatedAt = new Date().toISOString()

      showSummaryModal({...display})

      if(display.published)
        display.publishedBefore = true

      await firebase.updateDisplay(id, display, saveAsTemplate)

			setTimeout(()=>{
				setSaving(false)
				setIsDirty(false)
			},1000)


    } catch (e) {
      console.error(e)
      setSaving(false)
      showToast({message: t('Failed to update'), isError: true})
    }
  }

  const showSummaryModal = ({published,publishedBefore}) => {
    if(published && publishedBefore === false){
      showModal({hideFooter: true, innerComponent: <SummaryModal display={display} hideModal={hideModal} userData={userData}/> })
    }

    if(!userData.firstDisplayCreated){
      firebase.updateUserField(userData.userId,{firstDisplayCreated: true})
        .then(()=>{
          window.addEventListener('blur',()=>{
            hideModal()
            setTimeout(()=>{
              if(localStorage.getItem("guidanceShown") !== 'yes'){
                showModal({hideFooter: true,backdrop: false, hideHeader: true, innerComponent: <VisibilityGuidance hideModal={hideModal} firebase={firebase} userData={userData}/>})
                localStorage.setItem("guidanceShown",'yes')
              }
            },500)

          })
        })
    }
  }


	const currentMode = () => {
		if(mainMode){
			return 'mainMode'
		}else if(thankYouMode){
			return 'thankYouMode'
		}else if(teaserMode){
			return 'teaserMode'
		}else if(couponBarMode){
			return 'couponBarMode'
		}
	}

  const updatePreview = (display) => {
    const childFrameObj = document.getElementById('livePreview');
    if(childFrameObj){
      if(timeoutHandle) clearTimeout(timeoutHandle)
      timeoutHandle = setTimeout(()=>{
        childFrameObj.contentWindow?.postMessage({...display, previewMode: currentMode()}, '*'); //window.postMessage
      },300)
    }
  }

  const handleChange = (e, subject = false) => {
    if(!e.target){
      console.error('Please provide an event. No data was saved.')
      return
    }

    if(subject === 'showUnlimited spin to winLogo' && !currentPlan.includesbranding){
      showModal(
        {
          hideFooter: true,
          innerComponent:<div className="align-items-center p-4">
            <h4 className="text-center">{t('To customize or remove Unlimited spin to win branding, upgrade your plan to Standard or higher.')}</h4>
            <div className="text-center mt-5">
                <a className="btn btn-primary mr-2 btn-lg" target="_blank" href={platform.pricingUrl}>
                  <i className="mdi mdi-rocket-launch-outline" /> {t('See plans')}
                </a>
              <Button onClick={hideModal} className="ml-2" color="warning" size="lg">{t('Do it later')}</Button>
            </div></div>
        }
      )
    }else{
      if(!subject){
        if(e.target.name){
          subject = e.target.name
        }
        else{
          console.error('Please provide a subject or name the input. No data was saved.')
          return
        }
      }
      display[subject] = e.target.value
      setDisplay(display)
    }
  }

  const handlePrizeChange = (prizes) => {
      display.wheelSegments = prizes
      setDisplay(display)
  }

  const hasConversion = () => {
    return display.showForm
  }

  const hasConversionWithCoupon = () => {
    //Note: SpinToWin's coupon.coupon is blank
    return hasConversion() && display.afterConverting.action === 'show-thank-you-view' && display.thankYouContents.some( content => content.type === 'coupon')
  }

  const hasEmailField = () => {
    return hasConversion() && display.forms.some(content => content.id === 'email' && content.enabled)
  }

  const isGame = () => {
    return _isGame(display.type)
  }

  const shouldShowAutomaticCoupon = (content) =>{
    return isGame() && thankYouMode && content.fixedContent
  }
	const modeMap = new Map([
		  ['mainMode', setMainMode],
			['thankYouMode', setThankYouMode],
			['teaserMode', setTeaserMode],
			['couponBarMode', setCouponBarMode],
	])
	const changeMode = (mode) => {
		for( let func of modeMap.values()){
			func(false)
		}

		if(modeMap.get(mode)){
			const setter = modeMap.get(mode)
			setter(true)
		}
	}

  useEffect(async () => {
    try{
      if(id){
        const display = await firebase.getDisplay(id)
        if(display){
					_setDisplay(display)
					localStorage.setItem('currentDisplayId',id)
				}
        else{
          await push('/displays')
        }
      }

    }catch(e){
      showToast({title: t('Error'), message: t('Something went wrong with the server'), isError:true})
    }
  }, [id]) //Don't use userData for refetching display because uploading images causing unintentional effects.

  return id && display && (
    <DisplayContext.Provider value={{
      id,
      display,
      setDisplay,
      handleUpdate,
      handleChange,
      handlePrizeChange,
      updatePreview,
			mainMode,
			setMainMode,
      thankYouMode,
      setThankYouMode,
			teaserMode,
      setTeaserMode,
			couponBarMode,
      setCouponBarMode,
      getContent,
      addContent,
      updateContent,
      removeContent,
      contentTabState,
      setContentTabState,
      saving,
      isDirty,
      hasConversion,
      hasConversionWithCoupon,
      isGame,
      shouldShowAutomaticCoupon,
      hasEmailField,
			changeMode
    }}>
      {children}
    </DisplayContext.Provider>
  );
};

const useDisplay = () => useContext(DisplayContext)
export {useDisplay, DisplayProvider}
