import {getDefaultFont} from "../../lib/defaultFonts";
import scarcity from "../../components/display-components/defaults/defaults-scarcity-bar"

export default {
  heading: {
    type: 'heading',
    title: 'Heading',
    alignment: "center",
    headingTag: "h2",
    font: getDefaultFont('heading'),
    textColor: {r: 0, g: 0, b: 0, a: 1},
    visibility: 'all'
  },
  text: {
    type: 'text',
    title: 'Text',
    visibility: 'all',
    font:getDefaultFont('text'),
  },
  button: {
    type: 'button',
    buttonType:'single',
    buttonStyle:'btn',
    title: 'Button',
    sizeMobile :'md',
    size:'md',
    buttonAction:'link',
    buttonTwoAction:'close',
    text: 'Button',
    buttonTwoText:'Button2',
    link:'https://',
    buttonTwoLink:'https://',
    textColor: {r: 255, g: 255, b: 255, a: 1},
    buttonTwoTextColor: {r: 255, g: 255, b: 255, a: 1},
    buttonColor: {r: 92, g: 61, b: 194, a: 1},
    buttonTwoColor: {r: 92, g: 61, b: 194, a: 1},
    visibility: 'all'
  },
  image: {
    type: 'image',
    title: 'Image',
    imageUrl: '',
    mobileWidth: 100,
    desktopWidth: 100,
    visibility: 'all'
  },
  countdown: {
    type: 'countdown',
    title: 'Countdown',
    endDatetime: parseInt((new Date().getTime()) / 1000 + 60 * 60 * 48),
    font: getDefaultFont('countdown'),
    fontWeight: 'regular',
    labelFont: getDefaultFont('countdown'),
    labelFontWeight: 'regular',
    endMessage:'Time is up',
    textColor: {r: 0, g: 0, b: 0, a: 1},
    labelColor: {r: 51, g: 51, b: 51, a: 1},
    endColor: {r: 51, g: 51, b: 51, a: 1},
    visibility: 'all',
    countdownWidth: 'center'
  },
  divider: {
    type: 'divider',
    title: 'Divider',
    visibility: 'all',
    thickness: 1,
    color: {r: 224, g: 224, b: 224, a: 1}
  },
  spacer: {
    type: 'spacer',
    title: 'Spacer',
    visibility: 'all',
    size: 12,
    sizeMobile: 6
  },
  coupon: {
    type: 'coupon',
    title: 'Coupon',
    coupon: {"couponCode":"SAMPLE","couponType":"static"},
    fontSize: 16,
    bgColor: {r: 255, g: 255, b: 255, a: 1},
    textColor: {r: 0, g: 0, b: 0, a: 1},
    borderColor: {r: 0, g: 0, b: 0, a: 1},
    stretch: 'inline',
    stretchMobile: 'block'
  },
  scarcity : {
    type: 'scarcity',
    title: 'Scarcity bar',
    ...scarcity
  }
}
