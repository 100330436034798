import React, { createContext, useContext, useState, useEffect } from 'react'
const AuthContext = createContext()

function AuthProvider({ children, userData, authUser, currentPlan,viewCountAndLimit }) {
  return (
    <AuthContext.Provider value={{ userData, authUser,currentPlan,viewCountAndLimit }}>{children}</AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth}