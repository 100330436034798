import 'bootstrap/dist/css/bootstrap.min.css';
import '../public/assets/scss/theme.scss';
import {useEffect, useState} from 'react'
import {ModalProvider, ToastProvider} from "../providers/ModalProvider";
import {ValidationProvider} from "../providers/ValidationProvider";
import 'pintura/pintura.css';
import '../public/assets/scss/react-draft-wysiwyg.css'
import PublicLayout from '../components/PublicLayout'
import initAuth from '../utils/initAuth'
import routeConfig from '../route-config.js'
import {useRouter} from "next/router";
import PrivateLayout from "../components/PrivateLayout";
import {useTranslation} from "react-i18next";
import ChromelessLayout from "../components/ChromelessLayout";
import "flatpickr/dist/flatpickr.min.css";
import {detectLang,gtagConfig} from '../lib/helpers'
import Script from 'next/script'
import 'react-medium-image-zoom/dist/styles.css'
import Cookies from 'js-cookie'
initAuth()


function MyApp({Component, pageProps}) {
  const {pathname, query,events} = useRouter()
  const routeInfo = getRouteInfo(pathname)
  const {t, i18n} = useTranslation()
  let title = t(routeInfo?.title || 'No title')
  const [render, setRender] = useState()
  let isPrivate = routeInfo?.private
  let isChromeless = routeInfo?.chromeless

  const handleRouteChange = (url) => {
    gtagConfig(url)
  }
  useEffect(() => {

    document.body.dataset.layout = 'horizontal'
    document.body.dataset.topbar = 'light'
    document.body.dataset.layoutSize = 'fluid'
    document.body.dataset.layoutScrollable = 'true'
		//prepare plUser for Tag manager
    window.plUser = {};

		//Load hotjar script
		if(!process.env.NEXT_PUBLIC_LIVE_URL.includes('localhost')){
			(function(h,o,t,j,a,r){
				h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
				h._hjSettings={hjid:2824235,hjsv:6};
				a=o.getElementsByTagName('head')[0];
				r=o.createElement('script');r.async=1;
				r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
				a.appendChild(r);
			})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
		}


    events.on('routeChangeComplete', handleRouteChange)

    //priority 1. The language user saved, 2. Depends on the language of user's locale, either en or ja, 3. default 'en'
    try {
      const currentLanguage = detectLang()
      if (i18n.language !== currentLanguage)
        i18n.changeLanguage(currentLanguage)
    } catch (e) {
      console.error(e)
    }

    setRender(true)

    return () => {
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])


  if (isChromeless) {
    return (
      <ChromelessLayout title={title}>
        <Component {...pageProps} />
      </ChromelessLayout>
    )
  }


  let layout = (
    <ValidationProvider>
      <ToastProvider>
        <ModalProvider>
          {isPrivate ?
            <PrivateLayout title={title}>
              <Component {...pageProps} />
            </PrivateLayout>
            :
            <PublicLayout title={title}>
              <Component {...pageProps} />
            </PublicLayout>
          }
        </ModalProvider>
      </ToastProvider>
    </ValidationProvider>
  )


  return (
    <>
      {render && layout}
      {!isChromeless &&
      [
        <Script>{`
          !function(e,t){if(!e.groove){var i=function(e,t){return Array.prototype.slice.call(e,t)},a={widget:null,loadedWidgets:{},classes:{Shim:null,Embeddable:function(){this._beforeLoadCallQueue=[],this.shim=null,this.finalized=!1;var e=function(e){var t=i(arguments,1);if(this.finalized){if(!this[e])throw new TypeError(e+"() is not a valid widget method");this[e].apply(this,t)}else this._beforeLoadCallQueue.push([e,t])};this.initializeShim=function(){a.classes.Shim&&(this.shim=new a.classes.Shim(this))},this.exec=e,this.init=function(){e.apply(this,["init"].concat(i(arguments,0))),this.initializeShim()},this.onShimScriptLoad=this.initializeShim.bind(this),this.onload=void 0}},scriptLoader:{callbacks:{},states:{},load:function(e,i){if("pending"!==this.states[e]){this.states[e]="pending";var a=t.createElement("script");a.id=e,a.type="text/javascript",a.async=!0,a.src=i;var s=this;a.addEventListener("load",(function(){s.states[e]="completed",(s.callbacks[e]||[]).forEach((function(e){e()}))}),!1);var n=t.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n)}},addListener:function(e,t){"completed"!==this.states[e]?(this.callbacks[e]||(this.callbacks[e]=[]),this.callbacks[e].push(t)):t()}},createEmbeddable:function(){var t=new a.classes.Embeddable;return e.Proxy?new Proxy(t,{get:function(e,t){return e instanceof a.classes.Embeddable?Object.prototype.hasOwnProperty.call(e,t)||"onload"===t?e[t]:function(){e.exec.apply(e,[t].concat(i(arguments,0)))}:e[t]}}):t},createWidget:function(){var e=a.createEmbeddable();return a.scriptLoader.load("groove-script","https://7c7ac7e9-936c-4451-af31-41ceda27610b.widget.cluster.groovehq.com/api/loader"),a.scriptLoader.addListener("groove-iframe-shim-loader",e.onShimScriptLoad),e}};e.groove=a}}(window,document);
          window.groove.widget = window.groove.createWidget();
          window.groove.widget.init('7c7ac7e9-936c-4451-af31-41ceda27610b', {});
        `}</Script>,
        <Script
          id="google-tagmanager"
          key="google-tagmanager"
          strategy="afterInteractive"
          src="https://www.googletagmanager.com/gtag/js?id=G-JZCXJ6JWLR"
          onLoad={() => {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function(){dataLayer.push(arguments);}
            window.gtag('js', new Date());

            let interval = window.setInterval(()=>{
              //if(window.plUser.id && !window.plUser.admin){//Remove admin access
							window.gtag('get','G-JZCXJ6JWLR','client_id',(clientId)=>{
								Cookies.set('_ga_id_only',clientId)
							})
              if(window.plUser.id){
                window.gtag('config', 'G-JZCXJ6JWLR', {
                  user_id: window.plUser.id
                })

                clearInterval(interval)
              }

            },1000)

          }}
        />
      ]
      }
    </>
  )
}

function getRouteInfo(pathname) {
  if (routeConfig[pathname]) {
    return routeConfig[pathname]
  } else {
    const rootDir = pathname.match(/^\/[^/]*/)[0]
    const rootRoute = routeConfig[rootDir]
    let child = rootRoute?.children?.find((child) => {
      const regex = new RegExp(child.regex)
      return pathname.match(regex)
    })
    if (child)
      child.private = child.private || rootRoute.private
    return child
  }
}


export default MyApp
