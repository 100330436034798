import React, {Component, useState, useEffect} from "react"
import Cookies from 'js-cookie'

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, NavLink,
} from "reactstrap"

//i18n
import {useTranslation, withTranslation} from "react-i18next"

import Image from 'next/image'
import Link from 'next/link'
import ShopifyEnableScript from "./integration-components/ShopifyEnableScript";
import WixEmbeddingScript from "./integration-components/WixEmbeddingScript"
import {useModal} from "../providers/ModalProvider";
import axios from 'axios'

const ProfileMenu = ({firebase, userData, platform,authUser, currentPlan}) => {
  const [menu, setMenu] = useState(false)
  const {t, i18n} = useTranslation()
  const {showModal,hideModal} = useModal()
  const toggle = () => {
    setMenu(!menu)
  }

  const showShopifyInstall = () => {
    showModal({hideFooter: true, innerComponent: <ShopifyEnableScript authUser={authUser} hideModal={hideModal} userData={userData} firebase={firebase} /> })
  }
  const showWixInstall = () => {
    showModal({hideFooter: true, innerComponent: <WixEmbeddingScript userData={userData} hideModal={hideModal} /> })
  }

  const breakScriptCache = async () => {
    try{
      await axios.post(`${process.env.NEXT_PUBLIC_SCRIPT_HOST}/delete-redis-script-cache`)
    }catch(e){
      console.error(e)
    }
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
            <span className="d-inline-block ms-1 text-nowrap overflow-hidden" style={{maxWidth:'130px', textOverflow:'ellipsis'}}>
              <i className="mdi mdi-cog-outline font-size-16 align-middle ms-1 mr-1"/>
							<span className="d-none d-lg-inline">{t('Settings')}</span>
            </span>
          <i className="mdi mdi-chevron-down d-inline-block"/>
        </DropdownToggle>
        <DropdownMenu right={true}>
					{currentPlan.planname !== 'Lifetime' &&
						<DropdownItem tag="div">
							<Link href={platform.pricingUrl}>
							<a>
							 <i className="mdi mdi-floor-plan font-size-16 align-middle ms-1"/>{' '}
						   {t("Change plan")}
							</a>
							</Link>
						</DropdownItem>
					}

          {platform.showProfile &&
          <DropdownItem tag="div">
            <Link href="/profile">
              <a>
                <i className="mdi mdi-account-circle-outline font-size-16 align-middle ms-1"/>{' '}
                {t("Profile")}
              </a>
            </Link>
          </DropdownItem>
          }
          {platform.showInstall &&
          <DropdownItem tag="div">
            <Link href="/install">
              <a>
                <i className="mdi mdi-code-braces font-size-16 align-middle ms-1"/>{' '}
                {t("Install code")}
              </a>
            </Link>
          </DropdownItem>
          }
          {platform.type === 'shopify' && <DropdownItem tag="div">

            <a onClick={showShopifyInstall}>
              <i className="mdi mdi-code-braces font-size-16 align-middle ms-1"/>{' '}
              {t("Unlimited spin to win installation")}
            </a>

          </DropdownItem>}
          {platform.type === 'wix' && !userData.wixScriptEnabled && <DropdownItem tag="div">
            <a onClick={showWixInstall}>
              <i className="mdi mdi-code-braces font-size-16 align-middle ms-1"/>{' '}
              {t("Unlimited spin to win installation")}
            </a>

          </DropdownItem>}
          {userData.admin && [
          <DropdownItem tag="div" key="impersonate">
            <Link href="/impersonate">
              <a>
                <i className="mdi mdi-account-circle font-size-16 align-middle ms-1"/>{' '}
                {t("Impersonate")}
              </a>
            </Link>
          </DropdownItem>,
          <DropdownItem tag="div" key="break-base-script">
            <a onClick={breakScriptCache}>
              <i className="mdi mdi-format-clear font-size-16 align-middle ms-1"/>{' '}
              {t("Break script cache")}
            </a>
          </DropdownItem>,
          <DropdownItem tag="div" key="template-manager">
            <Link href="/template-manager?type=spin-to-win">
            <a>
            <i className="mdi mdi-clipboard-edit font-size-16 align-middle ms-1"/>{' '}
          {t("Template manager")}
            </a>
            </Link>
            </DropdownItem>
          ]
          }
          <DropdownItem tag="div" key="help-link">
            <Link href="https://unlimited-spin-to-win.groovehq.com/help">
              <a target="_blank">
                <i className="mdi mdi-help-circle-outline font-size-16 align-middle ms-1"/>{' '}
                {t("Help")}
              </a>
            </Link>
          </DropdownItem>
          {(platform.showLogout || Cookies.get('impersonate-login')) &&
            <>
              <div className="dropdown-divider" />
              <a href="" className="dropdown-item"
                 onClick={(e) => {
                   e.preventDefault();
                   firebase.updateUserDataState(null)
                   firebase.logout().then(() => {
                     firebase.user = null
                   });
                 }}
              >
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>{' '}
                <span>{t("Logout")}</span>
              </a>
            </>
          }
          <style jsx>{`
          .dropdown-divider:first-child{
            display:none;
          }
        `}</style>
        </DropdownMenu>
      </Dropdown>

    </>
  )
}

export default ProfileMenu
