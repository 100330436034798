export default {
  '/': {
    title: 'Unlimited spin to win',
    private: true,
  },
  '/profile': {
    title: 'Profile',
    private: true,
  },
  '/login' : {
    title: 'Login',
    private: false,
  },
  '/register' : {
    title: 'Sign up',
    private: false,
  },
  '/signup' : {
    title: 'Sign up',
    private: false,
  },
  '/shopify/signin' : {
    title: 'Login',
    private: false,
  },
  '/shopify/app' : {
    title: 'Login',
    private: false,
  },
	'/shopify/pricing' : {
		title:'Pricing',
		private: true,
	},
  '/oauthLogin':{
    title: 'Login',
    private: false
  },
  '/install' : {
    title: 'Install Unlimited spin to win',
    private: true,
  },
  '/forgot-password' : {
    title: 'Forgot password',
    private: false,
  },
  '/terms-of-use' : {
    title: 'Terms of use',
    private: false,
  },
  '/pricing' : {
    title: 'Pricing',
    private: true,
  },
  '/template-manager' : {
    title: 'Template manager',
    private: true,
  },
  '/coupon-histories' : {
    title: 'Coupon history',
    private: true,
  },
  '/preview':{
    title: 'Preview',
    chromeless:true,
  },
  '/displays' : {
    title: 'Displays',
    private: true,
    children: [
      {
        id: 'display-rules',
        title: 'Rules',
        regex: '^\/displays.*\/rules$'
      },
      {
        id: 'display-actions',
        title: 'Actions',
        regex: '^\/displays.*\/actions$'
      },
      {
        id: 'display-preview',
        title: 'Preview',
        regex: '^\/displays.*\/preview',
        chromeless: true
      },
      {
        id: 'display-create',
        title: 'Create New Campaign',
        regex: '^\/displays\/create'
      },
      {
        id: 'display-starters',
        title: 'Display starters',
        regex: '^\/displays\/starters'
      },
      {
        id: 'report',
        title: 'Report',
        regex: '^\/displays.*\/report'
      },
      {
        id: 'coupon-histories',
        title: 'Coupon History',
        regex: '^\/displays.*\/coupon-histories'
      },
      {
        id: 'display-edit',
        title: 'Edit display',
        regex: '^\/displays.*$'
      },

    ]
  },
  '/ab-test' : {
    title: 'A/B test',
    private: true,
    children: [
      {
        id: 'create-test',
        title: 'Create test',
        regex: '^\/ab-test\/create$'
      },
      {
        id: 'select-template',
        title: 'Select a template',
        regex: '^\/ab-test\/select-template'
      },
			{
				id: 'abtest-report',
				title: 'A/B Test report',
				regex: '^\/ab-test.*/report$'
			},
      {
        id: 'edit-test',
        title: 'Test settings',
        regex: '^\/ab-test.*/settings$'
      },
    ]
  },
  '/lists' : {
    title: 'Lists',
    private: true,
    children: [
      {
        id: 'create-list',
        title: 'Create list',
        regex: '^\/lists\/create$'
      },
      {
        id: 'view-list',
        title: 'View list',
        regex: '^\/lists.*$'
      },
    ]
  },
  '/coupons' : {
    title: 'Coupons',
    private: true,
    children: [
      {
        id: 'create-coupon',
        title: 'Create coupon',
        regex: '^\/coupons\/create$'
      },
      {
        id: 'view-coupons',
        title: 'View coupons',
        regex: '^\/coupons.*$'
      },
    ]
  },
  '/email-templates' : {
    title: 'Email templates',
    private: true,
    children: [
      {
        id: 'create-template',
        title: 'Create template',
        regex: '^\/email-templates\/create$'
      },
      {
        id: 'edit-template',
        title: 'Edit template',
        regex: '^\/email-templates.*$'
      },
    ]
  },
  '/impersonate': {
    title: "Impersonate",
    private: true,
  },
  '/shopify' : {
    title: 'Shopify',
    private: false,
    children: [
      {
        id: 'pricing',
        title: 'Pricing',
        regex: '^\/shopify\/pricing$',
        private: true,
      },
			{
				id: 'pricing-public',
				title: 'Pricing',
				regex: '^\/shopify\/pricing_public$',
				private: false,
			},
      {
        id: 'afterSubscribed',
        title: 'After subscribed',
        regex: '^\/shopify\/afterSubscribed',
        private: true,
      },
      {
        id: 'signin',
        title: 'Sign in',
        regex: '^\/shopify\/signin'
      },
    ]
  },
	'/wix/signin' : {
		title: 'Login',
		private: false,
	},
	'/wix' : {
		title: 'Wix',
		private: false,
		children: [
			{
				id: 'pricing',
				title: 'Pricing',
				regex: '^\/wix\/pricing$',
				private: true,
			},
			{
				id: 'pricing-public',
				title: 'Pricing',
				regex: '^\/wix\/pricing_public$',
				private: false,
			},
			{
				id: 'signin',
				title: 'Sign in',
				regex: '^\/wix\/signin',
				private: true,
			},
			{
				id: 'Login',
				title: 'Login',
				regex: '^\/wix\/app'
			},
		]
	},
}
