import React, {useState,useEffect,createContext,useContext} from "react";
import {useTranslation} from "react-i18next";
import {PinturaEditorModal} from "react-pintura";
import {useAuth} from "./AuthProvider";
import {useFirebase} from "./FirebaseProvider";
import {getEditorDefaults} from "pintura";
import {useDisplay} from "./DisplayProvider";

const ImageEditorContext = createContext();
const ImageEditorProvider = ({children}) => {
  const {userData} = useAuth()
  const {firebase} = useFirebase()
  const {display, setDisplay, getContent, updateContent} = useDisplay()
  const [visible, setVisible] = useState(false)
  const [type,setType] = useState()
  const [id, setId] = useState()
  const {t} = useTranslation()

  const saveImage = async (newImage) => {
    const uploaded = await firebase.uploadUserFile(newImage)
    const url = await uploaded.ref.getDownloadURL()
    if(type === 'content'){
      const content = getContent(id)
      content.imageUrl = url
      updateContent(content)
    }else if(type === 'display'){
      display.imageUrl = url
      setDisplay(display)
    }else if(type === 'display-mobile'){
			display.imageUrlMobile = url
			setDisplay(display)
		}

    if(!userData.uploadedImages)
      userData.uploadedImages = []
    userData.uploadedImages.unshift(url)

    await firebase.updateUserData(userData)
  }

  const getSrc = () => {
    if(type === 'content'){
      const content = getContent(id)
      return content.imageUrl
    }else if(type === 'display'){
      return display.imageUrl
    }else if(type === 'display-mobile'){
			return display.imageUrlMobile
		}
  }

  const showImageEditor = (type,id) => {
    setVisible(true);
    setType(type)
    if(id) setId(id)
  }
  const hideImageEditor = () => {
    setVisible(false)
  }

  const myLocale = {
    labelButtonExport: t('Save'),
    cropLabel: t('Crop'),
    annotateLabel: t('Annotate'),
    filterLabel: t('Filter'),
    resizeLabel: t('Resize'),
    decorateLabel: t('Decorate'),
    finetuneLabel: t('FineTune'),
    cropLabelButtonRotateLeft: t('Rotation left'),
    cropLabelTabRotation:t('Rotation'),
    cropLabelButtonFlipHorizontal:t('Flip horizontal'),
    cropLabelTabZoom:t('Zoom'),
    shapeLabelToolSharpie:t('Sharpie'),
    shapeLabelToolEraser:t('Eraser'),
    shapeLabelToolLine:t('Line'),
    shapeLabelToolArrow:t('Arrow'),
    shapeLabelToolRectangle:t('Rectangle'),
    shapeLabelToolEllipse:t('Ellipse'),
    shapeLabelToolText:t('Text'),
    labelSizeExtraSmall:t("Extra small"),
    labelSizeMediumSmall:t("Medium small"),
    labelSizeSmall:t("Small"),
    labelSizeMedium:t("Medium"),
    labelSizeLarge:t("Large"),
    labelSizeMediumLarge:t("Medium large"),
    labelSizeExtraLarge:t("Extra large"),
    finetuneLabelBrightness:t("Brightness"),
    finetuneLabelContrast:t("Contrast"),
    finetuneLabelSaturation:t("Saturation"),
    finetuneLabelExposure:t("Exposure"),
    finetuneLabelTemperature:t("Temperature"),
    finetuneLabelGamma:t("Gamma"),
    finetuneLabelClarity:t("Clarity"),
    finetuneLabelVignette:t("Vignette"),
  }


  return (
    <ImageEditorContext.Provider value={{showImageEditor,hideImageEditor}}>
      {children}
      {visible && <PinturaEditorModal
        {...getEditorDefaults({locale:myLocale})}
        src={getSrc()}
        onProcess={({ dest }) => saveImage(dest)}
        onHide={()=>setVisible(false)}/> }
    </ImageEditorContext.Provider>
  );
};

const useImageEditor = () => useContext(ImageEditorContext)

export {useImageEditor,ImageEditorProvider}