const languages = {

  en: {
    label: "English",
    flag: '/assets/images/flags/us.jpg',
  },
  ja: {
    label: "日本語",
    flag: "/assets/images/flags/japan.jpg",
  },
}

export default languages
