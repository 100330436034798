import axios from 'axios'

async function send(clientId, userId, events){

	const BASE_URL = process.env.NEXT_PUBLIC_LIVE_URL;

	if(!Array.isArray(events)){
		events = [events]
	}

	try{
		const res = await axios.post(`${BASE_URL}/api/measurement`,{
			userId: userId,
			clientId: clientId,
			events: events
		})

		return res.data

	}catch(e){
		return [null,e]
	}

}

export default {send}


