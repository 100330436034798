import {detectLang} from "./helpers";

const notoSans =  {
    family: "Noto Sans",
    variants:[
        "regular", "italic", "700", "700italic"
    ]
}

const openSans = {
    category: "sans-serif",
    family: "Open Sans",
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'greek-ext', 'hebrew', 'latin', 'latin-ext', 'vietnamese'],
    variants: ['300', 'regular', '500', '600', '700', '800', '300italic', 'italic', '500italic', '600italic', '700italic', '800italic'],
}

const roboto = {
    family: "Roboto",
    variants: ['100', '100italic', '300', '300italic', 'regular', 'italic', '500', '500italic', '700', '700italic', '900', '900italic']
}

const notsansjp = {
    family: "Noto Sans JP",
    variants: ['100', '300', 'regular', '500', '700', '900']
}

const currentLang = detectLang()

const defaultFonts = {
    heading: currentLang === 'ja' ? notsansjp : roboto,
    countdown: currentLang === 'ja' ? notsansjp : roboto,
    slideIn: currentLang === 'ja' ? notsansjp : roboto,
    text: currentLang === 'ja' ? notsansjp : roboto,
    tab: currentLang === 'ja' ? notsansjp : roboto,
}

export const getDefaultFont = (key) => defaultFonts[key]
export default defaultFonts
