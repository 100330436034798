import _ from 'lodash'
import axios from 'axios'
import dayjs from 'dayjs'
import i18n from "i18next";

export class AnalyticsHelper {
  analyticsHost = process.env.NEXT_PUBLIC_ANALYTICS_HOST
  analyticsData = {}
  isSimple = false
  dataLoaded = false
  dataContainer = {}
  totalCountContainer = {}
  start = null
  end = null
  series = []
  totals = {
    view:0,
    click:0,
    lead:0,
    convert:0,
    cvr:0,
    ctr:0
  }
  cachedData = {}
  defaultDateRange = []
  dateRange = []
  constructor() {
    this.setStartEndDate()
    this.createDefaultDateRange()
  }
  setStartEndDate(){
    this.start = dayjs().subtract(30,'days')
    this.end = dayjs()
  }
  saveDefaultOptions(){
    this.defaultOptions = _.cloneDeep(this.options)
  }
  setOptions(options,isSimple, useConversion){
    this.isSimple = !!isSimple
    this.options = options
    this.useConversion = useConversion
    if(isSimple){
      this.dateRange = this.defaultDateRange
      this.setStartEndDate() // reset date range
    }
  }
  createDefaultDateRange = () => {
    let days = this.start
    while(days <= this.end) {
      this.defaultDateRange.push(days.format('YYYY-MM-DD'))
      days = days.add(1,'days')
    }
    this.dateRange = [...this.defaultDateRange]
  }
  setDateRange(start,end){

    if(start && start.isSame(this.start) && (end && end.isSame(this.end))){
      return;
    }
    if(start)
      this.start = start
    if(end)
      this.end = end

    let days = this.start
    this.dateRange = []
    while(days <= this.end) {
      this.dateRange.push(days.format('YYYY-MM-DD'))
      days = days.add(1,'days')
    }
  }

  async getAnalyticsData(displayId) {
    if(!displayId)
      throw new Error('No display ID')

    if(!this.start || !this.end)
      throw new Error('No date range')

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const start = this.start.format('YYYY-MM-DD')
    const end = this.end.format('YYYY-MM-DD')
    if(this.cachedData[start+end+displayId]){
      return this.cachedData[start+end+displayId]
    }

    try{
      const {data} = await axios.get(`${this.analyticsHost}/analytics/${displayId}?timezone=${encodeURIComponent(timezone)}&fromdate=${start}&todate=${end}`)
      this.cachedData[start+end+displayId] = data
      return data;
    }catch(e){
      console.error(e)
      this.cachedData[start+end+displayId] = []
      return []
    }
    //this.analyticsData = data
  }

  async getAnalyticsByUser(userId) {
    try{
      const {data} = await axios.get(`${this.analyticsHost}/viewCountAndLimit/${userId}`)
      return data
    }catch(e){
      console.error(e)
      return {limit: 5000,viewCount: 0}
    }
  }

  async getAnalytics(displayId){

    this.analyticsData[displayId] = await this.getAnalyticsData(displayId)

    this.setDataByAction(displayId)

    return  {options: this.options,series: this.series}
  }

  getCVR() {
    const clicks = this.totals.click
    const views = this.totals.view
    const dates = this.options.xaxis.categories
    let cvr = []
    dates.forEach( (date, i) => {
      const rate = views[i] ? parseFloat((clicks[i] / views[i]).toFixed(2)) : 0
      cvr.push(rate)
    })
    return cvr
  }


  setDataByAction(displayId) {
    this.series = []
    this.options.yaxis = []
    let dataByDate = {
      view:[],
      convert:[],
      click:[]
    }
    let analyticsItems = {
      view:{
        action: 'view',
        enabled: true,
        show:!this.isSimple,
        title: {text: i18n.t('Views')},
        type:'line',
        seriesName: i18n.t("Views"),
      },
      click:{
        action: 'click',
        enabled: !this.useConversion,
        show:!this.isSimple,
        title: {text: i18n.t('Clicks')},
        type:'line',
        seriesName: i18n.t("Clicks"),
      },
      convert:{
        action: 'convert',
        enabled: this.useConversion,
        show:!this.isSimple,
        title: {text: i18n.t('Conversions')},
        type:"line",
        seriesName: i18n.t("Conversions"),
      },
      cvr:{
        action: false,
        enabled: this.useConversion,
        show:!this.isSimple,
        opposite: true,
        title: {text: 'CVR'},
        type:"column",
        seriesName: i18n.t("CVR"),
      },
      ctr:{
        action: false,
        enabled: !this.useConversion,
        show:!this.isSimple,
        opposite: true,
        title: {text: i18n.t('CTR')},
        type:"column",
        seriesName: i18n.t("Click through rate"),
      },
    }

    _.forEach(analyticsItems,(item,key)=>{

      if(!item.action || !item.enabled) return true

      const action = item.action
      const filtered = this.analyticsData[displayId].filter(datum => datum.action === action)


      this.totals[action] = filtered.length ? _.sumBy(filtered,(o)=> +o.count) : 0


      dataByDate[action] = {}
      filtered.forEach(item => {
        dataByDate[action][item.date] =  {x: item.date, y: +item.count}
      })

      this.dateRange.forEach((date) => {
        if (!dataByDate[action][date]) {
          dataByDate[action][date] = {x: date, y: 0}
        }
      })
      //Convert from an object to an array sorted by date
      dataByDate[action] = _.sortBy( Object.values(dataByDate[action]),'x')
      this.series.push({name:analyticsItems[action].seriesName,type:'line',data:dataByDate[action] })
      this.options.yaxis.push(analyticsItems[action])
    })

    if(this.totals.view && this.totals.click){
      this.totals.ctr = (this.totals.click / this.totals.view * 100).toFixed(1)
    }else{
      this.totals.ctr = 0
    }

    if(this.totals.view && this.totals.convert){
      this.totals.cvr = (this.totals.convert / this.totals.view * 100).toFixed(1)
    }else{
      this.totals.cvr = 0
    }

    if(!this.isSimple){
      //Get CVR or CTR
      ['cvr','ctr'].forEach((key)=>{

        if(!analyticsItems[key].enabled) return true

        let data = []

        _.forEach(dataByDate.view, (view,index) => {
          let numerator
          if(key === 'ctr')
            numerator = dataByDate.click[index]
          else if(key === 'cvr')
            numerator = dataByDate.convert[index]

          if(view.y && numerator && numerator.y ){
            const rate = (100 * (numerator.y / view.y)).toFixed(1)
            data.push({x:view.x,y:parseFloat(rate)})
          }else{
            data.push({x:view.x,y:0})
          }
        })

        this.series.push({name:analyticsItems[key].seriesName,type:'column',data:data})
        this.options.yaxis.push(analyticsItems[key])
      })
    }else{
      //For the index view, we should save the total data per display id
      this.totalCountContainer[displayId] = {...this.totals}
    }


    //return _.sortBy(dataByAction, [(o) => o.date]).map( datum => +datum.count);
  }
}



export default new AnalyticsHelper()
