import {Alert,Button, Input, Spinner, Label, FormGroup, FormFeedback,Row,Col} from 'reactstrap'
import {useTranslation} from "react-i18next";
import {useState} from "react";

const VisibilityGuidance = ({hideModal,firebase,userData}) => {
	const {t,i18n} = useTranslation()
	const [showSpinner1, setShowSpinner1] = useState(false)
	const [showSpinner2, setShowSpinner2] = useState(false)
	const goLink = (e, status) => {

		if(status === 'success'){
			hideModal()
		}else if( status === 'warning'){

			window.open(
				'https://unlimited-spin-to-win.groovehq.com/help/i-can-t-see-my-spin-to-win',
				'_blank'
			);

		}else if( status === 'danger') {
			window.open(
				'https://unlimited-spin-to-win.groovehq.com/help/i-can-t-see-my-spin-to-win',
				'_blank'
			);

		}else if(status === 'later'){
			hideModal()
			firebase.updateUserField(userData.userId,{firstDisplayCreated: false})
				.catch(e=>console.error(e))
			localStorage.setItem('guidanceShown','no')
		}
	}

	return (
		<>
			<Row>
				<Col className="p-3 text-center">
					<h4> {t('It looks like you were testing your display.')}</h4>
					<h4 className="pb-4"><em> {t('Did everything work ok?')}</em></h4>
				</Col>
			</Row>
			<Row className="pb-3 align-items-center">
				<Col><Button size="lg" block={true} color="success" onClick={e=> goLink(e, 'success')}><i className="mdi mdi-check-circle-outline" /> {t('It’s working well, no worries!')}</Button></Col>
			</Row>
			{/*<Row className="pb-3 align-items-center">*/}
			{/*	<Col><Button color="warning" block={true} size="lg" onClick={e=> goLink(e, 'warning')}>*/}
			{/*		{showSpinner1 ? <Spinner size="sm"/> :*/}
			{/*			<><i*/}
			{/*				className="mdi mdi-emoticon-sad-outline"/> {t('It’s displaying, but I’m not satisfied with the look')}</>*/}
			{/*		}</Button>*/}
			{/*	</Col>*/}
			{/*</Row>*/}
			<Row className="pb-3 align-items-center">
				<Col>
					<Button size="lg" block={true}  color="danger" onClick={e=> goLink(e, 'danger')}>
					{showSpinner2 ? <Spinner size="sm"/> :
						<><i className="mdi mdi-alert-outline" /> {t('It didn’t display at all, something is wrong')}</>
					}</Button>
					</Col>
			</Row>
			<Row className="pb-3 align-items-center">
				<Col><Button size="lg" block={true}  color="secondary" onClick={e=> goLink(e, 'later')}><i className="mdi mdi-clock-outline" /> {t('I wasn’t testing it, ask me again later')}</Button></Col>
			</Row>
		</>
	)
}

export default VisibilityGuidance