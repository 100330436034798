import React, {useState, useEffect, createContext, useContext} from "react";
const FirebaseContext = createContext();
const FirebaseProvider = ({children,firebase}) => {

  return (
    <FirebaseContext.Provider value={{firebase}}>
      {children}
    </FirebaseContext.Provider>
  );
};

const useFirebase = () => useContext(FirebaseContext)
export {useFirebase, FirebaseProvider}