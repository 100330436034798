import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import _ from 'lodash'
import axios from "axios";
import {detectLang,gtagEvent} from "../lib/helpers";
import Cookies from 'js-cookie'
import measurementHelper from './apiHelpers/measurementHelper'
import {sendDataToCrisp,sendDataToKlaviyo} from "./userDataSync";
const firestore = firebase.firestore

class FirebaseHandler {

  user = null
  setUserData = null
  sentRegistration = false

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          data => {
            if (data.user?.uid) {
              this.addNewUserToFirestore(data.user)
                .then((userData) => {
                  this.afterLogin(userData)
										.then(() => {
											resolve(data.user)
										})
                })
            }
          },
          error => {
            reject(error)
          }
        )
    })
  }

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          async ({user}) => {
            const userData = await this.getUserData(user.uid)
            await this.afterLogin(userData)
            resolve(user)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  loginWithOAuth = (customToken) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithCustomToken(customToken)
        .then(
          async ({user}) => {
            const userData = await this.getUserData(user.uid)
            if(userData)
              await this.afterLogin(userData, true)
            resolve(user)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  //For impersonate
  loginWithCustomToken = (customToken) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithCustomToken(customToken)
        .then(
          async ({user}) => {
            resolve(user)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  afterLogin = async (userData,oauth) => {
    try {

      const currentLang = this.getCurrentLanguage()
      //const currentPlan = await this.getCurrentPlan(userData)

      this.refreshKlaviyo(userData)
        .catch(e => console.error(e))


			const clientId = Cookies.get('_ga_id_only') ?? ''
			this.updateUserField(userData.userId,{gaClientId:clientId })
				.then(()=>{
					const platform = userData.platform ? userData.platform.name : 'generic'
					if(this.isRegistration(userData)){
						measurementHelper.send(clientId,userData.userId, {name:'sign_up',params:{method: platform}})
					}else{
						measurementHelper.send(clientId,userData.userId, {name:'login',params:{method: platform}})
					}
				})


		}catch(e){
      console.error(e)
    }
  }


  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  removeAuthCookies = async () => {
    fetch('/api/logout')
      .catch(e => {
        console.error(e)
      })
  }
  unsubscribeSnapshot = async () => {
    this.listening = false
    if(this.unsubscribe)
      await this.unsubscribe()
  }
  /*
   * Logout the user
   */
  logout = () => {

    return new Promise(async (resolve, reject) => {
      await this.unsubscribeSnapshot()
      //await this.removeAuthCookies()
      firebase
        .auth()
        .signOut()
        .then(async () => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Social Login user with given details
   */
  socialLoginUser = (data, type) => {
    let credential = {}
    if (type === "google") {
      credential = firebase.auth.GoogleAuthProvider.credential(data.idToken, data.token)
    } else if (type === "facebook") {
      credential = firebase.auth.FacebookAuthProvider.credential(data.token)
    }
    return new Promise((resolve, reject) => {
      if (!!credential) {
        firebase.auth().signInWithCredential(credential)
          .then(user => {
            resolve(this.addNewUserToFirestore(user))
          })
          .catch(error => {
            reject(this._handleError(error))
          })
      } else {
        reject(this._handleError(error))
      }
    })
  }

  addNewUserToFirestore = async (user) => {
    const collection = firestore().collection("users")

    const details = {
      userId: user.uid,
      firstName: '',
      lastName: '',
      fullName: user.email ?? '',
      email: user.email,
      createdDtm: firestore.FieldValue.serverTimestamp(),
      lists: [{name: 'Default', id: uniqueId()}],
      coupons: [{name: '10% off', id: uniqueId()}],
      uploadedImages: [],
    }

    const data = {...details}

    await collection.doc(user.uid).set(data)

    return data
  }

  setLoggeedInUser = (user, userData) => {

    localStorage.setItem("authUser", JSON.stringify(user))
    localStorage.setItem("userData", JSON.stringify(userData))
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null
    return JSON.parse(localStorage.getItem("authUser"))
  }

  getDisplays = async (includesTest = false) => {
    const query = await firestore().collection("popups")
    const snapshot = await query.where('userId', '==', this.user.id).get()

    let displays = []
    snapshot.forEach((doc, index) => {
      const data = doc.data()
      //Skip items controlled by AB test
      if(includesTest || !data.abTest) displays.push({...data, id: doc.id})
    })

    return displays
  }

  saveTags =  async(tags,templateId) => {
    const query = await firestore().collection('templates')
    const doc = query.doc(templateId)
    await doc.update({tags})
  }

  saveHighlight =  async(highlight,templateId) => {
    const query = await firestore().collection('templates')
    const doc = query.doc(templateId)
    await doc.update({highlight})
  }

  saveTemplateOrder = async (templates) =>{
    const query = await firestore().collection("templates")

    templates.forEach( (template,index) => {
      const doc = query.doc(template.id)
      doc.update({'_ordering':index})
    })
  }
  deleteTemplate = async (templateId) => {
    const docRef = await firestore().collection("templates").doc(templateId)
    await docRef.delete()
  }

  getTemplates = async (type = 'popup') => {
    const query = await firestore().collection("templates")
    const snapshot = await query.where('type', '==', type).get()

    let templates = []

    snapshot.forEach((doc, index) => {
      templates.push({id: doc.id, ...doc.data()})
    })

    return _.orderBy(templates, ['_ordering'], ['asc'])
  }

  getAllTemplates = async () => {
    const query = await firestore().collection("templates")
    const snapshot = await query.get()

    let templates = []

    snapshot.forEach((doc, index) => {
      templates.push({id: doc.id, ...doc.data()})
    })

    return _.orderBy(templates, ['_ordering'], ['asc'])
  }

  getDisplay = async (id) => {
    const docRef = firestore().collection("popups").doc(id)
    const doc = await docRef.get();
    if(!doc.exists) return null
    return {...doc.data(), id: id}
  }

//Add new display
  addDisplay = async (display,userData) => {
    const docRef = await firestore().collection("popups").add(display)
    try{

      //sendDataToCrisp(userData.email,{'display_created':1}).catch(e=>console.error(e))
      sendDataToKlaviyo(userData.email,{'display_created':1}).catch(e=>console.error(e))

      gtagEvent({
        action:'displayInteraction',
        params:{
          display_type: display.type,
          action: 'create'
        }
      })
    }catch(e){
      console.log(e)
    }

    return docRef.id
  }

  updateDisplay = async (id, display, saveAsTemplate) => {

    const docRef = firestore().collection("popups").doc(id)
    await docRef.update(display);

		if(display.abTest){
			this.processScreenShot(display).then((res)=>{
				window.dispatchEvent(new CustomEvent('on-screenshot-updated',{detail:{displayId:display.id,destination:res.data.destination}}))
				console.log('shoot!')
			})
		}

    await this.purgeCache()
    gtagEvent({
      action:'displayInteraction',
      params:{
        display_type: display.type,
        action: 'edit'
      }
    })

    if (saveAsTemplate) {
      await this.updateTemplate(display)
    }
    return true
  }

  //This is for displays
  updateField = async (id, field, value) => {
    const docRef = firestore().collection("popups").doc(id)
    await docRef.update({[field]: value});
    await this.purgeCache()
    return true
  }

  //This is for users
  updateUserField = async (userId, updates) => {
    const docRef = firestore().collection("users").doc(userId)
    await docRef.set(updates,{merge:true})
    return true
  }

  purgeCache = async () =>{
    const scriptHost = process.env.NEXT_PUBLIC_SCRIPT_HOST
    const deleteDisplayCache = `${scriptHost}/delete-redis-cache/${this.user.id}`
    try{
      await axios.post(deleteDisplayCache,{})

    }catch(e){
      console.error(e)
    }
  }

  onFinishInstruction = async () => {
    try{
      await this.updateUserField(this.user.id,{finishedInstruction:true})
			this.setUserData?.((userData) => {
				return {...userData, finishedInstruction: true}
			})
    }catch(e){
      console.error(e)
    }
  }

  processScreenShot = async (display) => {
    return await axios.post(`${process.env.NEXT_PUBLIC_SCREENSHOT_HOST}/screenshot?us2w=true`,{display})
  }

  previewScreenShot = (display) => {
    let newDisp = {}
    const removeKeys = [
      'title',
      'enterEmailInto',
      'thankYouContents',
      'triggersWhen',
      'shouldTheDisplayRecur',
      'showToUsersWho',
      'whenToShow',
      'followUpEmail',
      'enterEmailInto',
      'tags',
      'createdAt',
      'updatedAt',
      'afterConverting',
      'thumbnail',
      'showPromolayerLogo',
      'webhookInfo',
      'showOnPages',
      'DontShowOnPages',
      'userId',
      'published',
      'publishedBefore',
      'effect'
    ]
    for(const key in display){

      if( !removeKeys.includes(key)){
        newDisp[key] = display[key]
      }

    }

    newDisp.showPromolayerLogo = false
    newDisp.forms = newDisp.forms.filter(form => form.enabled)

    delete newDisp.font.variants
    delete newDisp.font.files

    const jsonDisplay = encodeURIComponent(JSON.stringify(newDisp))

    const url = `https://d.app.promolayer.io/preview?display=${jsonDisplay}`;
    window.open(url, '_blank')
  }

  updateTemplate = async (display) => {

    const templateDocRef = await firestore().collection("templates").doc(display.id)

    const copiedDisplay = _.cloneDeep(display)
    delete copiedDisplay.id
    delete copiedDisplay.templateId
    delete copiedDisplay.userId
    delete copiedDisplay.tags
    delete copiedDisplay._ordering
    copiedDisplay.followUpEmail = [{email: '', timing: 'immediately'}]
    copiedDisplay.enterEmailInto = {promolayerList: '', mailchimpList: ''}
    copiedDisplay.whenToShow.perpetual = true

    templateDocRef.set(copiedDisplay,{merge:true})

    this.processScreenShot(display).then(()=>{
      console.log('Shoot!')

    }).catch((e)=>{ console.error(e)})
  }



  bulkUpdateTemplates = async () => {
    //saori id EEazi8xNIHbn3fWUx3I49jvOGBZ2
    const query = await firestore().collection("templates")
    const type = 'header-footer'
    const snapshot = await query.where('type','==',type).get()

    let templates = []
    snapshot.forEach( (doc, index) => {

      //const data = doc?.data()

      //doc.ref.set({showOnPages:[],dontShowOnPages:[]},{merge: true})

    })
  }


  deleteDisplay = async (display) => {
    try {
      const docRef = firestore().collection("popups").doc(display.id)
      await docRef.delete();
      gtagEvent({
        action: 'displayInteraction',
        params: {
          display_type: display.type,
          action: 'delete'
        }
      })
      await this.purgeCache()
      return [true, null]
    } catch (e) {
      return [null, e]
    }
  }

  duplicateDisplay = async (id, defaults = null, titleChange = true) => {
    try{
      let data = await this.getDisplay(id)

			if(titleChange) data.title += ' (Copy)'

			data.published = false

			if(defaults){
        data = {...data, ...defaults}
      }
      delete data.id
      const duplicatedRef = await firestore().collection("popups").add(data)
      return [{...data,id:duplicatedRef.id},null]
    }catch(e){
      return [null,e]
    }
  }

  uploadImage = async (dataUrl, oldUrl) => {
    const storage = await firebase.storage()
    const storageRef = storage.ref();
    const imageRef = storageRef.child('images')

    let fileName = ''
    if (oldUrl
      && /^https:\/\/firebasestorage/.test(oldUrl)) {

      fileName = this.getFileNameFromUrl(oldUrl) || uniqueId()
    } else {
      fileName = uniqueId()
    }

    const photoRef = imageRef.child(fileName)

    return await photoRef.putString(dataUrl, 'data_url')
  }

  uploadUserFile = async (file) => {

    const storage = await firebase.storage()
    const storageRef = storage.ref();

    const imageRef = storageRef.child(`uploaded/${this.user.id}/${uniqueId()}`)

    return await imageRef.put(file)
  }


  getEmailTemplates = async (userData) => {
    const query = firestore().collection("emailTemplates")
    const snapshot = await query.where('userId', '==', userData.userId).get()
    let templates = []
    snapshot.forEach((doc, index) => {
      templates.push({id: doc.id, ...doc.data()})
    })

    return _.orderBy(templates, ['name'], ['asc'])
  }

  getDefaultTemplates = async (lang) => {
    const query = firestore().collection("defaultEmailTemplates")
    const snapshot = await query.get()
    let templates = []
    snapshot.forEach((doc, index) => {
      const data = doc.data()
      if(lang === 'en' && data.name.match(/^[a-z\s]+$/i) ){
        templates.push({id: doc.id, ...data})
      }

      if(lang === 'ja' && !data.name.match(/^[a-z\s]+$/i) ){
        templates.push({id: doc.id, ...data})
      }

    })

    return _.orderBy(templates, ['name'], ['asc'])
  }

  copyToDefaultTemplates = async () => {
    const query = firestore().collection("emailTemplates")
    const templates = await query.where('userId', '==', 'P6BIsH1Q8hdm5GF37esGl4MxKML2').get()

    const defaultTemplates = firestore().collection("defaultEmailTemplates")
    const defaultTemplateSnapshot = await defaultTemplates.get()

    //Delete defaultEmail templates
    defaultTemplateSnapshot.forEach((doc, index) => {
      doc.ref.delete()
    })

    templates.forEach( (doc) => {
      defaultTemplates.add(doc.data())
    })
  }

  getEmailTemplate = async (id) => {
    const query = firestore().collection("emailTemplates")
    const docRef = await query.doc(id).get()
    return docRef.data()
  }

  getDefaultEmailTemplate = async (id) => {
    const query = firestore().collection("defaultEmailTemplates")
    const docRef = await query.doc(id).get()
    return docRef.data()

  }

  saveEmailTemplate = async (data, id) => {

    data.userId = this.user.id
    const query = await firestore().collection("emailTemplates")
    if (id) {
      const ref = query.doc(id)
      return ref.set(data, {merge: true})
    } else {
      return query.add(data)
    }
  }

  deleteEmailTemplate = async (id) => {
    const ref = await firestore().collection("emailTemplates").doc(id)
    return ref.delete()
  }

  getUserDataDocRef = async (userId) => {
    userId = userId || this.user.id
    const query = await firestore().collection("users")
    return query.doc(userId)
  }

  getTestSubscription = async () => {
    const docRef = await firestore().collection("users").doc("bsUrEZaeZRe2x3ACLQS4gt7i9O42")
    const doc = await docRef.get()
    return await doc.data().subscription
  }
  getUserData = async (userId) => {
    userId = userId || this.user.id
    const docRef = await this.getUserDataDocRef(userId)
    const doc = await docRef.get()
    const data = await doc?.data()

    // if (!data) {
    //   const {details} = this.addNewUserToFirestore(this.user)
    //   return details
    // }
    return data
  }

  listenUserDataChanges = (userId, callback) => {
    if (!this.listening) {
      this.listening = true
      this.unsubscribe = firestore().collection("users").where('userId','==',userId)
        .onSnapshot( querySnapshot => {
          querySnapshot.docChanges().forEach(change => {
            if (change.type === 'modified') {
              callback(change.doc.data())
            }else if(change.type === 'removed') {
              callback(false)
            }
          });
        //callback()
      })
      return this.unsubscribe
    }
  }

  //This is called when every time user logged in
  updateUserData = async (userData,sendGetgist) => {
    const query = await firestore().collection("users")
    const doc = await query.doc(userData.userId)
    await doc.set(userData, {merge: true})

    this.updateUserDataState(userData)
  }

  createSendingData = async (userData) => {
    const currentPlan = await this.getCurrentPlan(userData)
    let installedWebsite = userData.installWebsite ?? ''
    const genericPlatform = userData.wordpress ? 'wordpress' : 'generic'
    const platform = userData.platform?.name ?? genericPlatform
    if(platform === 'wordpress' && Array.isArray(userData.wordpress?.sites)){
      installedWebsite = userData.wordpress.sites[0].siteUrl
    }

    return {
      app_name: 'us2w',
      full_name: userData.fullName ?? userData.email,
      plan_name: currentPlan.planname.toLowerCase(),
      installed_website: installedWebsite,
      language: userData.preferredLanguage ?? this.getCurrentLanguage(),
      install_platform: platform.toLowerCase(),
      user_id: userData.userId,
      sign_up_date: Math.floor(this.getCreatedAt(userData.createdDtm).getTime() / 1000),
      uninstall:0
    }
  }

  refreshCrisp = async (userData) => {
    const data = await this.createSendingData(userData)
    await sendDataToCrisp(userData.email, data)
  }

  refreshKlaviyo = async (userData) => {
    const data = await this.createSendingData(userData)
    await sendDataToKlaviyo(userData.email, data)
  }

  getCurrentLanguage = () => {
    return detectLang()
  }

  updateUserDataState = (userData) => {
    if(this.setUserData)
      this.setUserData(_.cloneDeep(userData))
  }

  deleteImage = async (url) => {
    const storage = await firebase.storage()
    const storageRef = storage.ref()
    const fileName = this.getFileNameFromUrl(url)

    if (fileName) {
      const fileRef = storageRef.child(`uploaded/${this.user.id}/${fileName}`);

      // Delete the file
      await fileRef.delete()
    } else {
      throw new Error('File not found')
    }
  }

  addCoupon = async (coupon,userData) => {
    coupon.id = uniqueId()
    userData.coupons.push(coupon)
    await this.updateUserData(userData)
  }

  deleteCoupon = async (couponId, userData) => {
    const index = userData.coupons.findIndex((item) => item.id === couponId)
    userData.coupons.splice(index, 1)
    //Delete all codes of this coupon
    const query = await firestore().collection("couponCodes")
    const codes = await query.where('couponId', '==', couponId).get()
    codes.forEach(doc => {
      doc.ref.delete()
    })
    await this.updateUserData(userData)
  }

  getCouponCodes = async (couponId) => {

    const query = await firestore().collection("couponCodes")
    const snapshot = await query.where('couponId', '==', couponId).where('userId','==',this.user.id).get()
    let list = []
    snapshot.forEach((doc, index) => {
      list.push({id: doc.id, ...doc.data()})
    })

    return list
  }

  addCouponCodes = async (couponCodes) => {
    const query = await firestore().collection("couponCodes")
    couponCodes.forEach(code => {
      query.add(code)
    })
  }

  deleteCouponCodes = async (docId) => {
    await firestore().collection("couponCodes").doc(docId).delete()
  }

  getCouponHistories = async (id,allOfUsers) => {
    const query = await firestore().collection("couponHistories")
    let snapshot
    if(allOfUsers){
      snapshot = await query.where('userId','==', id).get()
    }else{
      snapshot = await query.where('displayId','==',id).where('userId','==', this.user.id).get()
    }

    const list = []
    snapshot.forEach(doc => {
      list.push({id: doc.id, ...doc.data()})
    })
    return list
  }

  deleteCouponHistories = async (checked) => {
    const ids = [...checked]
    const deleteRecursive = async () => {
      const tenIds = ids.splice(0,10)

      const snapshot = await firestore().collection("couponHistories")
        .where(firestore.FieldPath.documentId(), "in", tenIds).get()

      if(!snapshot.empty){
        snapshot.forEach( (doc, index) => {
          doc.ref.delete()
        })
      }

      if(ids.length > 0)
        return await deleteRecursive()

    }
    await deleteRecursive()
  }

  addEmailList = async (list,userData) => {
    list.id = uniqueId()
    userData.lists.push(list)
    await this.updateUserData(userData)
  }

  deleteEmailListItems = async (listId, checked) => {
    const ids = [...checked]
    const deleteRecursive = async () => {
      const tenIds = ids.splice(0,10)

      const snapshot = await firestore().collection("emailList")
        .where(firestore.FieldPath.documentId(), "in", tenIds).get()

      if(!snapshot.empty){
        snapshot.forEach( (doc, index) => {
          doc.ref.delete()
        })
      }

      if(ids.length > 0)
        return await deleteRecursive()

    }
    await deleteRecursive()
  }

  insertToEmailList = async (data) => {
    const query = await firestore().collection("emailList")
    await query.add(data)
  }

  deleteEmailList = async (listId,userData) => {
    const index = userData.lists.findIndex((item) => item.id === listId)
    userData.lists.splice(index, 1)
    const query = await firestore().collection("emailList")
    const snapshot = await query.where('userId', '==', this.user.id).where('listId', '==', listId).get()
    snapshot.forEach( (doc, index) => {
      doc.ref.delete()
    })
    await this.updateUserData(userData)

  }

  getUserEmailList = async (listId) => {
    const query = await firestore().collection("emailList")
    const snapshot = await query.where('userId', '==', this.user.id).where('listId', '==', listId).get()
    let list = []
    snapshot.forEach((doc, index) => {
      list.push({id: doc.id, ...doc.data()})
    })
    return list
  }

  getABTests = async () => {
    const query = await firestore().collection("abTests")
    const snapshot = await query.where('userId', '==', this.user.id).get()

    let tests = []
    for(let doc of snapshot.docs.values()){

      const data = doc.data()
      data.id = doc.id
      data.displays = await this.getDisplaysControlledByTest(data.id)
      tests.push(data)
    }


    return _.orderBy(tests,'updatedAt','desc')
  }


  getDisplaysControlledByTest = async (testId) => {
    const query = await firestore().collection("popups")
    const snapshot = await query.where('abTest', '==', testId).get()
    let displays = []
    snapshot.forEach((doc, index) => {
      displays.push({id: doc.id, ...doc.data()})
    })
    return displays
  }

  addABTest = async (test) => {
    try{
      const query = await firestore().collection("abTests")
      test.createdAt = new Date().toISOString()
      test.updatedAt = new Date().toISOString()
      const ref = await query.add(test)
      return [ref,null]
    }catch(e){
      return [null, e]
    }
  }

	duplicateABTest = async (_test) => {
		try{
			const test = _.cloneDeep(_test)
			// const query = await firestore().collection("abTests")
			delete test.id
			delete test.startedAt
			delete test.finishedAt

			test.enabled = false

			const [ref,error] = await this.addABTest(test)

			if(error) throw new Error('Can not duplicate the test')

			const newTestId = ref.id
			const newDisplays = []


			for(let display of test.displays){
				const [data,error] = await this.duplicateDisplay(display.id,{
					published: true,
					publishedBefore: true,
					abTest: newTestId
				}, false)

				newDisplays.push(data)
			}

			//Update test props
			test.id = ref.id
			test.displays = newDisplays
			test.createdAt = new Date().toISOString()
			test.updatedAt = new Date().toISOString()

			return [test, null]
			//const result = await query.add(test)
			//return [result,null]
		}catch(e){
			return [null, e]
		}
	}

  updateABTest = async (id,_test) => {
    try{
      const test = _.cloneDeep(_test)
      const query = await firestore().collection("abTests").doc(id)
			test.updatedAt = new Date().toISOString()
      delete test.displays
      await query.set(test,{merge:true})
			await this.purgeCache()
      return [true, null]
    }catch(e){
      return [null, e]
    }
  }

	updateABTestStatus = async (id,status) => {
		try{
			const query = await firestore().collection("abTests").doc(id)
			const data = {
				updatedAt: new Date().toISOString(),
				enabled: status,
			}
			await query.set(data,{merge:true})
			await this.purgeCache()
			return [true, null]
		}catch(e){
			return [null, e]
		}
	}

  getABTest = async (testId) => {
    try{
      const docRef = await firestore().collection("abTests").doc(testId)
      const doc = await docRef.get()
      let dataWithId = {...doc.data(), id: doc.id }
      dataWithId.displays = await this.getDisplaysControlledByTest(testId)
      return [dataWithId,null]
    }catch(e){
      return [null, e]
    }
  }

  deleteABTest = async (testId) => {
    try{
      const doc = await firestore().collection("abTests").doc(testId)
      await doc.delete()
      const query = await firestore().collection("popups")
      const snapshot = await query.where('abTest','==',testId).get()
      snapshot.forEach(doc => {
        doc.ref.delete()
      })
			await this.purgeCache()
      return [true,null]
    }catch(e){
      return [null, e]
    }

  }

  getUserFileList = async () => {
    const storage = await firebase.storage()
    const storageRef = storage.ref()
    const fileRef = storageRef.child('uploaded/' + this.user.id);
    return await fileRef.listAll()
  }

  saveSubscriptionPlan = async (data, id) => {
    const query = await firestore().collection("subscriptionPlans")

    const ref = query.doc(id)
    return ref.set(data, {merge: true})
  }

  getCurrentPlan = async (userData) => {
    try{
      if(userData.platform?.name === 'wix' || userData.platform?.name === 'shopify' ){
        if(userData.platform.currentPlan === 'Free'){
          const plans = await this.getSubscriptionPlans('free')
          return plans[0]
        }else{
          const plans = await this.getSubscriptionPlans(userData.platform.name)
          return plans.find(item=> Object.values(item).includes(userData.platform.currentPlan))
        }

      }else{
        if(userData?.subscription?.price_id){
          const plans = await this.getSubscriptionPlans(userData.billingPlatform?.platform ?? 'stripe')
          return plans.find(item=> Object.values(item).includes(userData.subscription.price_id))
        }else{
          const plans = await this.getSubscriptionPlans('free')
          return plans[0] //
        }
      }
    }catch(e){
      return {
        planviews: 100,
        planname: 'Free',
        includesbranding: false
      }
    }

  }

  getSubscriptionPlans = async (platform,withFree) => {
    const query = await firestore().collection("subscriptionPlans")
    const snapshot = await query.where('platform', '==', platform).get()
    let plans = []
    if(withFree){
      plans.push(await this.getFreePlan())
    }
    snapshot.forEach((doc, index) => {
      plans.push({id: doc.id, ...doc.data()})
    })
    return _.orderBy(plans, ['planviews'], ['asc'])
  }

  getFreePlan = async () => {
    const query = await firestore().collection("subscriptionPlans")
    const snapshot = await query.where('platform', '==', 'free').get()
    const doc = snapshot.docs[0]
    const data = await doc.data()
    return {id:doc.id, ...data}
  }


  getFileNameFromUrl = (url) => {
    if (/^https:\/\/firebasestorage/.test(url)) {
      const regex = new RegExp(/firebasestorage.googleapis.com\/(.*%2F){2}([^?]*)/)
      const res = regex.exec(url)
      return res && res[2]
    }
    return null
  }

  getTemplateThumbnail = async (id) => {
    const storage = await firebase.storage()
    const storageRef = await storage.ref(`templateThumbnails/${id}.jpg`)
    return storageRef.getDownloadURL()
  }

  setUserHandler = (user, setUserData) => {
    this.user = user
    this.setUserData = setUserData
  }

  cloneSubscriptionPlans = async (fromPlatform,toPlatform) => {
    const plans = await this.getSubscriptionPlans(fromPlatform)

    plans.forEach(item => {
      const doc = firestore().collection('subscriptionPlans').doc()
      item.platform = toPlatform
      doc.set(item)
    })
  }

	isRegistration =  (data) => {
		try{
			let createdAt
			if(data.createdDtm.seconds){
				createdAt = new Date(data.createdDtm.seconds * 1000)
			}else{
				createdAt = data.createdDtm.toDate()
			}

			return (Date.now() - createdAt.getTime() < 1000 * 30)
		}catch(e){
			return true
		}
	}

  getCreatedAt = (createdDtm) => {
    try{
      let createdAt
      if(createdDtm.seconds){
        createdAt = new Date(createdDtm.seconds * 1000)
      }else{
        createdAt = createdDtm.toDate()
      }

      return createdAt
    }catch(e){
      return new Date()
    }
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _fireBaseHandler = null


/**
 * Returns the firebase backend
 */
const getFirebaseHandler = () => {

  if (!_fireBaseHandler) {
    _fireBaseHandler = new FirebaseHandler()
  }
  return _fireBaseHandler
}


/**
 * Returns unique ID
 */
export const uniqueId = function () {
  return '_' + Date.now().toString(32) + Math.random().toString(36).substr(2, 10);
};



export {getFirebaseHandler}
