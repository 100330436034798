import React, {useState, useEffect} from "react";
import Head from "next/head";
import Header from "./Header";

import i18n from "../i18n"
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import classnames from 'classnames'
import {FirebaseProvider} from "../providers/FirebaseProvider";
import {getFirebaseHandler} from "../utils/firebaseHandler";
const PublicLayout = ({children,error,title}) => {

    const {pathname} = useRouter()
    const firebase = getFirebaseHandler()
    const hideHeader = () => {
      return ['/register','/login'].includes(pathname)
    }
    return (
        <>
            <FirebaseProvider firebase={firebase}>
              <Head>
                  <title>{title}</title>
                  <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
                  <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
                  <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
                  <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
                  <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
                  <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
                  <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
                  <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
                  <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
                  <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png" />
                  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                  <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
                  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                  <meta name="theme-color" content="#2e86de" />
              </Head>
             { !hideHeader && <Header title={title}/>}

							<main className={classnames({'main-content':!hideHeader()})}>
								{error ? (<div>{error}</div>) :<div>{children}</div>}
							</main>
            </FirebaseProvider>
        </>
    );
};
export default PublicLayout

PublicLayout.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    error: PropTypes.string
};
