const Logo = (props) => {

    const {
        mode = 'light'
    } = props;

    let fill = mode === 'dark' ? '#333' : '#fff';

    return (
        <svg
            viewBox="0 0 771 78"
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 2,
                width:"100%",
            }}
            {...props}
        >
            <g transform="scale(1.04764 1)">
                <path
                    style={{
                        fill: "none",
                    }}
                    d="M0 0h735v78H0z"
                />
                <clipPath id="a">
                    <path d="M0 0h735v78H0z"/>
                </clipPath>
                <g clipPath="url(#a)">
                    <path
                        style={{
                            fill: "none",
                        }}
                        d="M0 0h734.229v77.371H0z"
                        transform="scale(.95453 1)"
                    />
                    <path
                        d="M368.966 546.853v68.414c0 10.875-3.48 19.488-10.441 25.84-6.961 6.351-16.231 9.527-27.809 9.527-11.718 0-21.023-3.129-27.914-9.387-6.89-6.257-10.336-14.941-10.336-26.05v-68.344h17.719v68.484c0 6.844 1.734 12.071 5.203 15.68 3.469 3.609 8.578 5.414 15.328 5.414 13.688 0 20.532-7.219 20.532-21.656v-67.922h17.718Zm100.196 102.375h-17.789l-45.633-72.633v72.633h-17.789V546.853h17.789l45.773 72.914v-72.914h17.649v102.375Zm38.812-14.203h46.477v14.203h-64.266V546.853h17.789v88.172Z"
                        style={{
                            fill: fill,
                            fillRule: "nonzero",
                        }}
                        transform="matrix(.71162 0 0 .74552 10.156 -407.692)"
                    />
                    <path
                        style={{
                            fill: fill,
                        }}
                        d="M569.146 546.853h17.719v102.375h-17.719z"
                        transform="matrix(.71162 0 0 .74552 10.156 -407.692)"
                    />
                    <path
                        d="m631.794 546.853 29.532 78.469 29.461-78.469h22.992v102.375H696.06v-33.75l1.758-45.141-30.235 78.891h-12.726l-30.164-78.82 1.758 45.07v33.75h-17.719V546.853h23.062Z"
                        style={{
                            fill: fill,
                            fillRule: "nonzero",
                        }}
                        transform="matrix(.71162 0 0 .74552 10.156 -407.692)"
                    />
                    <path
                        style={{
                            fill: fill,
                        }}
                        d="M735.857 546.853h17.719v102.375h-17.719z"
                        transform="matrix(.71162 0 0 .74552 10.156 -407.692)"
                    />
                    <path
                        d="M847.373 561.197h-31.922v88.031h-17.649v-88.031h-31.64v-14.344h81.211v14.344Zm73.265 42.328h-42.047v31.5h49.149v14.203h-66.938V546.853h66.446v14.344h-48.657v28.265h42.047v14.063Zm21.586 45.703V546.853h30.234c9.047 0 17.075 2.015 24.082 6.047 7.01 4.031 12.43 9.75 16.28 17.156 3.84 7.406 5.76 15.891 5.76 25.453v5.133c0 9.703-1.93 18.234-5.8 25.594-3.86 7.359-9.37 13.031-16.521 17.015-7.148 3.985-15.34 5.977-24.574 5.977h-29.461Zm17.789-88.031v73.828h11.602c9.328 0 16.488-2.918 21.48-8.754 4.992-5.836 7.535-14.215 7.625-25.137v-5.695c0-11.11-2.41-19.594-7.238-25.453-4.828-5.86-11.836-8.789-21.024-8.789h-12.445Z"
                        style={{
                            fill: fill,
                            fillRule: "nonzero",
                        }}
                        transform="matrix(.71162 0 0 .74552 10.156 -407.692)"
                    />
                    <path
                        d="M18.6 6.62c-1.44 0-2.8.56-3.77 1.53L7.8 14.39c-.64.64-1.49.99-2.4.99C3.53 15.38 2 13.87 2 12c0-1.87 1.53-3.38 3.4-3.38.91 0 1.76.35 2.44 1.03l1.13 1 1.53-1.34L9.22 8.2A5.37 5.37 0 0 0 5.4 6.62C2.42 6.62 0 9.04 0 12s2.42 5.38 5.4 5.38c1.44 0 2.8-.56 3.77-1.53l7.03-6.24c.64-.64 1.49-.99 2.4-.99 1.87 0 3.4 1.51 3.4 3.38 0 1.87-1.53 3.38-3.4 3.38-.9 0-1.76-.35-2.44-1.03L15 13.34l-1.5 1.34 1.28 1.12a5.386 5.386 0 0 0 3.82 1.57c2.98 0 5.4-2.41 5.4-5.37 0-3-2.42-5.38-5.4-5.38Z"
                        style={{
                            fill: fill,
                            fillRule: "nonzero",
                        }}
                        transform="matrix(6.86367 0 0 7.19066 0 -46.602)"
                    />
                </g>
            </g>
        </svg>
    )
}

export default Logo