import {Button,Input,Spinner,Label} from 'reactstrap'
import Cookies from 'js-cookie'
import {useTranslation} from "react-i18next";
import axios from 'axios'
import React, {useState,useEffect} from "react";
const WixEmbeddingScript = ({authUser,userData,hideModal}) => {
  const {t,i18n} = useTranslation()

  const clicked = async () => {
    try{
      const result = await axios.post('/api/wix/embedScript', {userData})
      if(result.data.success){
        hideModal()
      }
    }catch(e){
      if(e.response.data.error.includes('connected domain')){
        alert(t('Embedded script apps can only be added by the owners of premium Wix sites with connected domains.'))
      }
    }
  }

  return (
    <div className="d-flex align-center flex-column justify-content-center" style={{'minHeight':'200px'}}>
      <h3 className="text-center my-3">{t('Unlimited spin to win installation')}</h3>
      <p>{t('Before Unlimited spin to win can function a script must be embedded into your website. This script loads your popups and shows them to your visitors.')}</p>
      <span>{t('Note')}:</span>
      <p dangerouslySetInnerHTML={{__html: t('Embedded script apps can only be added by the owners of premium Wix sites with connected domains. Besides, your store must have https enabled.')}}></p>
      <div className="mt-4">

      <>
        <div className="mt-4 text-center">
          <Button color="primary" onClick={clicked}>
            {t('Enable Unlimited spin to win')}
          </Button>
          <Button className="ml-2" color="warning" onClick={hideModal}>{t('Do it later')}</Button>
        </div>
      </>

      </div>
    </div>
  )
}

export default WixEmbeddingScript