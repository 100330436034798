import {Button, Input, Spinner, Label, FormGroup, FormFeedback,Row,Col,Alert} from 'reactstrap'
import {useTranslation} from "react-i18next";
import React, {useState,useEffect} from "react";
import dayjs from 'dayjs'
import languageList from "../../lib/languagesList";
import {isGame, getCouponOnThankYou, getCouponOnMain} from "../../lib/helpers"
const SummaryModal = ({display,hideModal,userData}) => {
  const {t,i18n} = useTranslation()

  const inTheTimeFrame = () => {
    return new Date(display.whenToShow.start).getTime() <= Date.now() && new Date(display.whenToShow.end).getTime() >= Date.now()
  }

  const isDisplaying = () =>{
    return display.published && (display.whenToShow.perpetual === true || inTheTimeFrame())
  }

  const outOfTheTimeFrame = () =>{
    return !display.whenToShow.perpetual && !inTheTimeFrame()
  }

  const invisibleReason = () => {
    const rtn = []
    if(!display.published){
      rtn.push(t('Draft mode'))
    }

    if(outOfTheTimeFrame()){
      rtn.push(t('When to show this display'))
    }
    return rtn.length > 0 ? rtn.join(', ') : ''
  }

  const showDateRange = () => {
    return `${dayjs(display.whenToShow.start).format('YYYY-MM-DD HH:mm')} ~ ${dayjs(display.whenToShow.end).format('YYYY-MM-DD HH:mm')}`
  }

  const showOnPages = (excludePages) => {
    let prop = 'showOnPages'
    if(excludePages) prop = 'dontShowOnPages'
    const labelMap  = {
      "equals":'URL Equals',
      "contains":'URL Contains',
      "startsWith":'URL Starts with',
      "endsWith":'URL Ends with',
    }
    const conditionArray = Array.isArray(display[prop]) ? display[prop].filter(item=> !!item.matchText) : []
    if(conditionArray.length === 0){
      return excludePages ? false : <dd>{t('Show on all pages')}</dd>
    }else{
      return conditionArray.map( (item,index) => {
        return (<dd key={index}>{t(labelMap[item.matchType])}: {item.matchText}</dd>)
      })
    }
  }

  const browserLanguage = () =>{
    if(!display.showToUsersWho.browserLanguage || display.showToUsersWho.browserLanguage === 'any') return false
    return languageList.find(item => item.code === display.showToUsersWho.browserLanguage).name
  }

  const cameFrom = () => {
    if(!display.showToUsersWho.cameFrom || display.showToUsersWho.cameFrom  === 'any') return false
    const map = {
      "facebook" : "Facebook",
      "instagram" : "Instagram",
      "youtube" : "Youtube",
      "twitter" : "Twitter",
      "tiktok" : "TikTok",
      "reddit" : "Reddit",
      "pinterest" : "Pinterest",
      "any-search-engine" : "Any search engine",
      "any-social-network" : "Any social network'",
      "in-app-browser" : "In app browser",
    }
    return t(map[display.showToUsersWho.cameFrom])
  }

  const deviceType = () =>{
    if(!display.showToUsersWho.deviceType || display.showToUsersWho.deviceType === 'any') return t('Any (Desktop & Mobile)')
    else if(display.showToUsersWho.deviceType === 'mobile') return t('Mobile only')
    else if(display.showToUsersWho.deviceType === 'desktop') return t('Desktop only')
  }

  const triggersWhen = () => {
    if(display.triggersWhen.immediate){
      return <dd>{t('Show immediately')}</dd>
    }else{
      const rtn = []
      if(display.triggersWhen.onExitIntent){
        rtn.push(<dd key="exit-intent">{t('Display on exit intent')}</dd>)
      }
      if(display.triggersWhen.useTimeOnSiteLongerThan){
        rtn.push(<dd key="time-on-site">{t('Time on site longer than')}: {display.triggersWhen.timeOnSiteLongerThan} {t('Seconds')}</dd>)
      }
      if(display.triggersWhen.useScrollDepthReaches){
        rtn.push(<dd key="scroll-depth">{t('Scroll depth reaches')}: {display.triggersWhen.scrollDepthReaches}%</dd>)
      }
      return rtn
    }
  }

  const showAgainAfterFormatted = () => {

    const value = display.shouldTheDisplayRecur.showAgainAfter
    if(value === 'every-page-view') return t('Show on Every page view')
    else{
      const [num,unit] = value.split(/(?=[a-z])/)
      return `${num} ${unit==='h'? t('hours') : t('days')}`
    }
  }

  const shouldTheDisplayRecur = () => {
      const rtn = []
      rtn.push( <dd key="show-again-after">{t('Show again after')}: {showAgainAfterFormatted()}</dd>)
      if(display.showForm && display.shouldTheDisplayRecur.showAgainIfConverts){
        rtn.push( <dd key="show-if-converts">{t('Show again if user converts')}</dd>)
      }
     return rtn
  }


  const isCouponSetImproperly = () => {

    try{
      const mainCoupon = getCouponOnMain(display)

      if (typeof mainCoupon === 'string' && (!mainCoupon || mainCoupon.toLowerCase() === 'sample') ) return true
      if(display.showForm){
        if(isGame(display.type)){
          const segmentCount = +display.wheelSegmentCount
          const usedSegments = display.wheelSegments.slice(0,segmentCount)
          return usedSegments.some(segment => (segment.coupon?.couponType === 'static' && segment.coupon.couponCode.toLowerCase() === 'sample' ))

        }else{
          const coupon = getCouponOnThankYou(display)
          return coupon === '' || (coupon?.couponType === 'static' && coupon.couponCode.toLowerCase() === 'sample')
        }
      }

    }catch(e){
      console.error(e)
    }
  }

  return (
    <div className="d-flex align-center flex-column justify-content-center">
      <div>
        <h4 className="text-center mb-2">{t("Summary")}: {display.title}</h4>
        <Row className="">
          <Col>
            <div className="p-2">
              {isDisplaying() &&
              <Alert color="success" className="">
                <Row>
                  <Col xs={2} className="d-flex align-items-center justify-content-center">
                    <i className="mdi mdi-24px mdi-eye-check"></i>
                  </Col>
                  <Col xs={10} className="d-flex align-items-center">
                    <div style={{'fontSize':'0.8rem'}} className="d-flex align-items-center justify-content-between w-100">{t('Display is currently live and visible')}
                      <Button target="_blank" href="https://unlimited-spin-to-win.groovehq.com/help/i-can-t-see-my-spin-to-win" className="ml-3" size="sm" color="warning">
                        <i className="mdi mdi-help-circle"/> {t("I can't see it")}</Button></div>
                  </Col>
                </Row>
              </Alert>
              }
              {!isDisplaying() &&
              <Alert color="warning" className="">
                <Row>
                  <Col xs={2} className="d-flex align-items-center justify-content-center">
                    <i className="mdi mdi-24px mdi-eye-off-outline"></i>
                  </Col>
                  <Col xs={10}>
                    <h5>{t('Currently this display is not visible')}</h5>
                    <div className="small"><strong>{t('Reason')}</strong>: {invisibleReason()}</div>
                  </Col>
                </Row>
              </Alert>
              }
              {isCouponSetImproperly() &&
              <Alert color="danger" className="small">
                <Row>
                  <Col xs={2} className="d-flex align-items-center justify-content-center">
                    <i className="mdi mdi-24px mdi-alert-outline"></i>
                  </Col>
                  <Col xs={10}>
                    <div>{t('Warning')}: {t('You may have forgotten to set up your coupon.')}</div>
                    <div>{t('An included coupon may be incorrect, it is blank or has not been changed from default.')}</div>
                  </Col>
                </Row>
              </Alert>}
              <div className="rule-summary">
                <dl className="">
                  <dt><i className="mdi mdi-clock-time-four" /> {t('When to show this display')}</dt>
                  <dd>{display.whenToShow.perpetual ? t('Perpetual') :showDateRange()}</dd>
                </dl>
                <dl>
                  <dt><i className="mdi mdi-web" /> {t('Show on these pages')}</dt>
                  {showOnPages()}
                </dl>
                {showOnPages(true) &&
                <dl>
                  <dt><i className="mdi mdi-eye-off-outline" /> {t("Don't show on these pages")}</dt>
                  {showOnPages(true)}
                </dl>
                }
                <dl>
                  <dt><i className="mdi mdi-account" /> {t('Show to users who')}</dt>
                  {browserLanguage() && <dd>{t('Browser language')}: {browserLanguage()}</dd>}
                  {cameFrom() && <dd>{t('Came from')}: {cameFrom()}</dd>}
                  <dd>{t('Using device type')}: {deviceType()}</dd>
                </dl>
                <dl>
                  <dt><i className="mdi mdi-alarm" /> {t('Triggers when')}</dt>
                  {triggersWhen()}
                </dl>
                <dl>
                  <dt><i className="mdi mdi-repeat" /> {t('Should the display recur?')}</dt>
                  {shouldTheDisplayRecur()}
                </dl>
              </div>

              <style jsx>{`
                .rule-summary {
                  position: relative;
                  padding: 0.75rem 1.25rem;
                  margin-bottom: 1rem;
                  border: 1px solid transparent;
                  border-radius: 0.25rem;
                  background-color: #e5e5e5;
                }
              `}</style>
            </div>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <Button className="ml-2" color="info" onClick={hideModal}>
              <i className="mdi mdi-close-circle"></i> {t('Close summary')}
            </Button>
            {/*{userData.installWebsite && <a target="_blank" href={userData.installWebsite} className="btn btn-info ml-2">Preview</a>}*/}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SummaryModal
